import {imageSize} from '@app/utils/imageSize';
import {generateQuery, useGQL} from './useGQL';

/**
 * @typedef {object} Show
 * @property {string} ageRate
 * @property {boolean} broadcast
 * @property {string} description
 * @property {{url: string}[]} images
 * @property {{value: string}[]} metadata
 * @property {{title: string, slug: string}[]} seasons
 * @property {string} slug
 * @property {string} title
 * @property {string} webSlug
 * @property {object} category
 * @property {string} category.title
 * @property {string} category.slug
 * @property {{value: string}[]} category.metadata
 */

/**
 * @typedef {object} getDataPayload
 * @property {string} showSlug
 * @property {string?} categorySlug
 * @property {string?} imageSize
 * @property {string?} imageType
 * @property {string?} seasonSort
 */

/**
 * @returns {{
 *  data: Show,
 *  getData: function(getDataPayload): Promise<{Show}>
 * }}
 */
export default function useDetail() {
  function parseResponse(data) {
    const broadcast = data?.show?.broadcast;

    const seasons = data?.show?.seasons?.node;

    return {
      ageRate: data?.show?.ageRate,
      broadcast: broadcast,
      description: data?.show?.description,
      images: data?.show?.images,
      metadata: data?.show?.metadata,
      seasons: seasons,
      slug: data?.show?.slug,
      title: data?.show?.title,
      webSlug: data?.show?.webSlug,
      category: data?.category,
    };
  }

  /**
   * @param {getDataPayload} payload
   */
  function getQuery(payload = {}) {
    return generateQuery(
      `query showDetail($showSlug: String!, $categorySlug: String!, $imageType: ImageTypeEnum!, $imageSize: String!, $seasonSort: SortEnum!) {
        show(slug: $showSlug){
          title
          slug
          description
          ageRate
          broadcast
          webSlug
          metadata {
            value
          }
          images(imageType: $imageType){
            url(size: $imageSize)
          }
          seasons(sort: $seasonSort) {
            node {
              title
              slug
              metadata {
                value
              }
            }
          }
        }
        category(slug: $categorySlug) {
          title
          slug
          metadata {
            value
          }
        }
      }`,
      {
        imageSize: imageSize(imageSize.type.banner),
        imageType: 'LANDSCAPE',
        seasonSort: 'SORT_DESC',
        ...payload,
      },
    );
  }

  return useGQL(parseResponse, getQuery);
}
