import {API_URL_AUTH, AUTH_CLIENT_ID} from '@app/constants';
import styles from '@app/styles/views/Login.module.sass';
import {useRef} from 'react';
import {useState, useEffect} from 'react';
import IconLogoFull from './icons/IconLogoFull';
import Loading from './Loading';

async function checkActivation(payload) {
  try {
    const response = await fetch(payload.url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: 'code=' + payload.code,
    });
    const result = await response.json();
    if (result?.access_token) {
      return Promise.resolve(result);
    } else {
      return Promise.reject({});
    }
  } catch (e) {
    return Promise.reject(e);
  }
}

export default function LoginFromLocal({onMessageHandler}) {
  const [loading, setLoading] = useState(true);
  const [oauthConfig, setOauthConfig] = useState({});
  const isRendered = useRef(false);

  function checking(payload) {
    setTimeout(() => {
      checkActivation(payload)
        .then(result => {
          if (typeof onMessageHandler === 'function') {
            onMessageHandler(result);
          }
        })
        .catch(() => {
          checking(payload);
        });
    }, 5000);
  }

  useEffect(() => {
    async function getData() {
      const url = `${API_URL_AUTH}/oauth/device-authorize?client_id=${AUTH_CLIENT_ID}`;
      setLoading(true);
      try {
        const response = await fetch(url + '&format=json', {
          method: 'GET',
        });
        const result = await response.json();
        checking({...result, url: url});
        setOauthConfig(result);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    if (!isRendered.current) {
      isRendered.current = true;

      getData();
    }
  }, []);

  return (
    <div className={styles.login}>
      {loading && <Loading />}
      {!loading && (
        <>
          <div className={styles.head}>
            <IconLogoFull width={127} height={40} />
            <h1 id="title">Iniciar sesión</h1>
          </div>
          <div className={styles.tv_content}>
            <div className={styles.column}>
              <div className={styles.column_inner}>
                <div className={styles.column_title}>
                  <img
                    className={styles.column_title_icon}
                    src="images/devices.png"
                    width="100"
                    height="58"
                    alt="Todos los dispositivos"
                  />
                  <h2 className={styles.column_title_label}>
                    Desde tu dispositivo
                    <br />
                    móvil o web
                  </h2>
                </div>
                <div className={styles.column_content}>
                  <p className={styles.column_line}>
                    1. Ingresa a nuestra URL:{' '}
                    <strong>{oauthConfig.activate_url}</strong>
                  </p>
                  <p className={styles.column_line}>
                    2. Cuando se te solicite ingresa el siguiente código
                  </p>
                  <p className={styles.column_code}>{oauthConfig.code}</p>
                </div>
              </div>
            </div>
            <div className={styles.column_separator}></div>
            <div className={styles.column}>
              <div className={styles.column_inner}>
                <div className={styles.column_title}>
                  <img
                    className={styles.column_title_icon}
                    src="images/mobile.png"
                    width="33"
                    height="58"
                    alt="Teléfono"
                  />
                  <h2 className={styles.column_title_label}>
                    Escanea el código QR
                  </h2>
                </div>
                <div className={styles.column_content}>
                  <p>
                    Utiliza la c&aacute;mara o lector de c&oacute;digos QR de tu
                    dispositivo m&oacute;vil y sigue las instrucciones
                  </p>
                  <img
                    className={styles.image_qr}
                    src={oauthConfig.qr_url}
                    height="170"
                    alt="Código QR"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
