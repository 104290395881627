import {API_URL, APP_NAME} from '@app/constants';
import {fetchApi} from '@app/api/repository';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {getUserToken} from '@app/stores/auth';

export default function useFavorites() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false);
  const token = useSelector(getUserToken);

  async function getData() {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchApi(
        `${API_URL}/favorites?app_name=${APP_NAME}`,
        {
          method: 'GET',
          headers: {
            Authorization: token,
          },
        },
      );
      const result = await response.json();
      setData(result?.shows);
      return Promise.resolve(result?.shows);
    } catch (e) {
      setError(e);
      return Promise.reject(e);
    } finally {
      setLoading(false);
    }
  }

  /**
   * @param {{ showSlug: string }} payload
   */
  async function getFavorite(payload = {}) {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchApi(
        `${API_URL}/favorites?slug=${payload.showSlug}`,
        {
          method: 'GET',
          headers: {
            Authorization: token,
          },
        },
      );
      const result = await response.json();
      setIsFavorite(result?.favorite);
      return Promise.resolve(result);
    } catch (e) {
      setError(e);
      setIsFavorite(false);
      return Promise.reject(e);
    } finally {
      setLoading(false);
    }
  }

  /**
   * @param {object} payload
   * @param {string} payload.token
   * @param {string} payload.showSlug
   */
  async function addFavorite(payload) {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchApi(`${API_URL}/favorite`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: payload.token,
        },
        body: `show_slug=${payload.showSlug}&app_name=${APP_NAME}`,
      });
      const result = await response.json();
      setIsFavorite(result?.ok);
      return Promise.resolve(result);
    } catch (e) {
      setError(e);
      return Promise.reject(e);
    } finally {
      setLoading(false);
    }
  }

  /**
   * @param {object} payload
   * @param {string} payload.token
   * @param {string} payload.showSlug
   */
  async function removeFavorite(payload) {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchApi(`${API_URL}/favorite`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: payload.token,
        },
        body: `show_slug=${payload.showSlug}&app_name=${APP_NAME}`,
      });
      const result = await response.json();
      setIsFavorite(result?.ok ? false : true);
      return Promise.resolve(result);
    } catch (e) {
      setError(e);
      return Promise.reject(e);
    } finally {
      setLoading(false);
    }
  }

  return {
    data,
    error,
    loading,
    getData,
    addFavorite,
    removeFavorite,
    getFavorite,
    isFavorite,
  };
}
