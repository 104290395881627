import appRoutes from '@app/appRoutes';
import useChaptersNews from '@app/hooks/useChaptersNews';
import {getBanners} from '@app/stores/banners';
import {getCategoriesShows} from '@app/stores/shows';
import styles from '@app/styles/views/Home.module.sass';
import {getShowImageSource} from '@app/utils/getShowImageSource';
import BannerMain from '@components/BannerMain';
import EpisodesByCategory from '@components/EpisodesByCategory';
import Loading from '@components/Loading';
import {
  focus,
  keyNavAttribute,
  keyNavSelectors,
  KEY_NAV,
} from '@components/KeyNavItem';
import ShowsByCategory from '@components/ShowsByCategory';
import {useLayoutEffect} from 'react';
import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import useHistory from '@app/hooks/useHistory';
import {getUserToken} from '@app/stores/auth';

export default function Home() {
  const [loading, setLoading] = useState(true);
  const showNews = useChaptersNews({loading: false});
  const bannerShow = useSelector(getBanners);
  const [showFocus, setShowFocus] = useState(null);
  const [banner, setBanner] = useState(null);
  const navigate = useNavigate();
  const categoriesShows = useSelector(getCategoriesShows);
  const ref = useRef();
  const isLayoutRendered = useRef();
  const history = useHistory();
  const token = useSelector(getUserToken);
  const [categories, setCategories] = useState(null);

  function onFocusItemGrid(data) {
    setShowFocus(data);
  }

  function onClickItemGrid(data) {
    if (data?.slug) {
      navigate(appRoutes.category(`${data.slug}`));
    }
  }

  function onClickItemGridShow(data) {
    if (data?.slug) {
      navigate(appRoutes.category(`${data?.slug}`));
    }
  }

  useEffect(() => {
    let b = null;

    if (showFocus) {
      if (bannerShow) {
        b = bannerShow[showFocus?.slug];
      }

      if (!b) {
        b = getShowImageSource(showFocus?.images);
      }

      setBanner(b);
    }
  }, [showFocus, bannerShow]);

  useLayoutEffect(() => {
    if (!isLayoutRendered.current) {
      let current = ref.current?.querySelector(`[${KEY_NAV.ITEM}]`);

      if (current) {
        isLayoutRendered.current = true;
        focus(current);
      }
    }
  });

  const history_timeout = useRef(0);

  useEffect(() => {
    setLoading(true);
    clearTimeout(history_timeout.current);
    history_timeout.current = setTimeout(async () => {
      let pre = [];
      let h = null;
      let n = null;

      if (token) {
        try {
          h = await history.getData();
          if (h.data && h.data?.length > 0) {
            pre.unshift({
              title: 'Continuar viendo',
              slug: 'continueViewing',
              shows: h.data,
            });
          }
        } catch (e) {
          console.log('history', e);
        }
      }
      try {
        n = await showNews.getData();
        if (n && n?.length > 0) {
          pre.push({
            slug: 'newsChapters',
            title: 'Añadidos recientemente',
            shows: n,
          });
        }
      } catch (e) {
        console.log('news', e);
      }

      if (categoriesShows?.length > 0) {
        setCategories([...pre, ...categoriesShows]);
      }
      setLoading(false);
    }, 100);
  }, [token, categoriesShows]);

  return (
    <div ref={ref} className={styles.main}>
      {loading && <Loading fixed={true} />}

      {!loading && (
        <>
          <div className={styles.banner}>
            <BannerMain source={banner} />
          </div>
          <div
            className={styles.body}
            {...keyNavAttribute(KEY_NAV.SCROLL_DOWN)}>
            {categories?.map((category, index) => {
              if (
                index === 0 &&
                !showFocus &&
                category?.shows &&
                category?.shows?.length > 0
              ) {
                onFocusItemGrid(category?.shows[0], -1);
              }

              if (
                category?.slug === 'newsChapters' ||
                category?.slug === 'continueViewing'
              ) {
                return (
                  <EpisodesByCategory
                    key={category?.slug}
                    label={category?.title}
                    data={category?.shows}
                    showInfoInBackground={category?.slug === 'continueViewing'}
                    onFocusItem={(data, index) => onFocusItemGrid(data, index)}
                    onClickItem={data => onClickItemGridShow(data, category)}
                    keynavdown={keyNavSelectors()
                      .steps(
                        'parentNode',
                        'parentNode',
                        'nextSibling',
                        KEY_NAV.ITEM_LAST_FOCUS,
                      )
                      .build()}
                    keynavup={keyNavSelectors()
                      .steps(
                        'parentNode',
                        'parentNode',
                        'previousSibling',
                        KEY_NAV.ITEM_LAST_FOCUS,
                      )
                      .build()}
                    keynavleft={keyNavSelectors()
                      .onlyIndex0(
                        `#menu [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                        `#menu [${KEY_NAV.ITEM}]`,
                      )
                      .build()}
                  />
                );
              } else {
                return (
                  <ShowsByCategory
                    key={category?.slug}
                    label={category?.title}
                    data={category?.shows}
                    onFocusItem={(data, index) => onFocusItemGrid(data, index)}
                    onClickItem={data => onClickItemGrid(data, category)}
                    keynavdown={keyNavSelectors()
                      .steps(
                        'parentNode',
                        'parentNode',
                        'nextSibling',
                        KEY_NAV.ITEM_LAST_FOCUS,
                      )
                      .build()}
                    keynavup={keyNavSelectors()
                      .steps(
                        'parentNode',
                        'parentNode',
                        'previousSibling',
                        KEY_NAV.ITEM_LAST_FOCUS,
                      )
                      .build()}
                    keynavleft={keyNavSelectors()
                      .onlyIndex0(
                        `#menu [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                        `#menu [${KEY_NAV.ITEM}]`,
                      )
                      .build()}
                  />
                );
              }
            })}
          </div>
        </>
      )}
    </div>
  );
}
