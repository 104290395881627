const ADD_SHOWS = 'ADD_SHOWS';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_SHOWS:
      return [...action.payload];
    default:
      return state;
  }
}

export function addShows(payload) {
  return async dispatch => {
    return dispatch({
      type: ADD_SHOWS,
      payload: payload,
    });
  };
}

export function getCategoriesShows(state) {
  return state?.shows;
}

export function getCategoriesShowsLength(state) {
  return state?.shows?.length;
}

export function getCategories(state) {
  return state?.shows?.map(category => {
    return {
      title: category?.title,
      slug: category?.slug,
      render: category?.render,
      webSlug: category?.webSlug,
      metadata: category?.metadata,
    };
  });
}

export function getCategory(state, categorySlug) {
  return state?.shows?.find(category => category?.slug === categorySlug);
}
