import styles from '@app/styles/components/Episodethumb.module.sass';
import NavItem, {keyNavProps} from './KeyNavItem';
import TagExclusive from './TagExclusive';
import TagFree from './TagFree';
import IconPlay from './icons/IconPlay';

/**
 * Episodethumb.
 *
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.slug
 * @param {string} props.image
 * @param {object} props.season
 * @param {string} props.season.slug
 * @param {string} props.season.title
 * @param {object} props.show
 * @param {string} props.show.slug
 * @param {string} props.show.title
 * @param {function} props.onFocus
 * @param {function} props.onClick
 * @param {string|string[]} props.keynavup
 * @param {string|string[]} props.keynavdown
 * @param {string|string[]} props.keynavleft
 * @param {string|string[]} props.keynavright
 * @param {boolean} showInfoInBackground - Show info in background
 */
export default function Episodethumb(props) {
  return (
    <NavItem
      {...keyNavProps(props)}
      className={[
        styles.wrap,
        props.showInfoInBackground && styles.backgroundInfo,
      ].join(' ')}
      onClick={props.onClick}
      onMouseEnter={e => {
        if (typeof props.onFocus === 'function') {
          props.onFocus(e);
        }
      }}>
      <div className={styles.main}>
        <div
          className={styles.picture}
          style={{
            backgroundImage: `url(${props.image})`,
          }}
        />
        {props.free !== undefined && props.free === true && (
          <TagFree styles={{position: 'absolute', right: 4, top: 4}} />
        )}
        {props.free !== undefined && props.free === false && (
          <TagExclusive styles={{position: 'absolute', right: 4, top: 4}} />
        )}
        <div className={styles.secundary}>
          <span className={styles.date}>{props?.show?.title}</span>
          <span className={styles.separator}>•</span>
          <span className={styles.title}>{props?.title}</span>
          {props.showInfoInBackground && (
            <span className={styles.iconContent}>
              <span className={styles.icon}>
                <IconPlay />
              </span>
              <span className={styles.iconLabel}>Reanudar</span>
            </span>
          )}
        </div>
      </div>
    </NavItem>
  );
}
