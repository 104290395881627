export const ERROR_LOGIN =
  'Ocurrió un error inesperado, intentalo de nuevo por favor.';
export const ERROR_TOKEN_EXPIRED = 'Tu sesión expiró';
export const ERROR_VIDEO_DATA =
  'Ocurrió un error inesperado. Por favor, vuelve a intentarlo';
export const ERROR_VIDEO_MEDIATOKEN_NULL =
  'No se puede visualizar el video porque no tienes una suscripción activa';

export const MESSAGES = {
  100101: 'Las credenciales de comunicación son incorrectas!',
  100102: 'La transacción debe enviar # CELULAR o DNI',
  100103: 'Enviar data',
  100104: 'Ok!',
  100105: 'Se registra solicitud con estado cancelado!',
  100106: 'Data incompleta...',
  100107: 'La data se guardó con éxito!',
  100108: 'El monto enviado no corresponde a ningún paquete de recarga.',
  100109: 'Lo siento, ocurrió un error, el usuario no estaba registrado',
  100110: 'Gracias por registrarse, ahora puede iniciar sesión',
  100111: 'Lo siento, no tiene un paquete asignado a este pago',
  100112: 'El usuario para suscribirse no está registrado',
  100113: 'Lo siento, se produjo un error interno, intente nuevamente',
  100114:
    'Su código se ha guardado correctamente. Ahora puede disfrutar de nuestro contenido',
  100115: '¡Ya está registrado en tvGO, por favor inicie sesión!',
  100116: '¡El dominio de correo está en la lista negra!',
  100117: 'Ingrese una dirección de correo electrónico válida',
  100118: 'Es obligatorio',
  100119: '¡El Apikey ingresado es incorrecto!',
  100120: 'Ingrese el mínimo permitido para el campo',
  100121: 'Ingrese el máximo permitido por el campo',
  100122: 'Debe tener una suscripcián activa para poder ver los videos',
  100123: 'Nombre de usuario y / o contraseña incorrectos',
  100124: 'Debe tener una suscripción activa',
  100125: 'Enviar id de usuario',
  100126:
    'Este Campo sólo puede contener caracteres alfabéticos y comillas simples',
  100127: 'El valor ingresado no es valido',
  100128: 'El Teléfono ingresado tiene formato inválido.',
  100129: 'La Fecha ingresada no tiene el formato correcto',
  100130: 'Debe ingresar un DNI válido',
  100131: 'Su nueva contraseña se le envió a su correo',
  100132: 'La lista de paquetes no existe para el partner ingresado',
  100133: 'Para ver los videos necesita renovar la suscripción',
};
