const SET_IS_POPUP_ACTIVE = 'SET_IS_POPUP_ACTIVE';
const SET_POPUP_MESSAGE = 'SET_POPUP_MESSAGE';

export const STATUS_TYPE = {
  CONNECTION_ERROR: 'Error de conexión',
  RECONNECTION_SUCCESS: 'Conexión reestablecida',
};

const initialState = {
  isOpen: false,
  message: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_IS_POPUP_ACTIVE:
      return {
        ...state,
        isOpen: action.payload,
      };
    case SET_POPUP_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    default:
      return state;
  }
}

export function setIsPopupActive(payload) {
  return dispatch => {
    return dispatch({
      type: SET_IS_POPUP_ACTIVE,
      payload: payload,
    });
  };
}
export function setPopupMessage(payload) {
  return async dispatch => {
    return dispatch({
      type: SET_POPUP_MESSAGE,
      payload: payload,
    });
  };
}

export function getIsOpenPopup(state) {
  return state?.popup?.isOpen;
}

export function getPopupMessage(state) {
  return state?.popup?.message;
}
