/**
 * @param {object} props
 * @param {string} props.fill - color
 * @param {number} props.width
 * @param {number} props.height
 */
export default function IconControlNext10(props) {
  const fill = props.fill || '#fff';
  const width = props.width || 64;
  const height = props.height || 64;

  return (
    <svg width={width} height={height} viewBox="0 0 64 64" fill="none">
      <path
        d="M48 34.6665C48 43.4932 40.8266 50.6665 32 50.6665C23.1733 50.6665 16 43.4932 16 34.6665C16 25.8398 23.1733 18.6665 32 18.6665V29.3332L45.3333 15.9998L32 2.6665V13.3332C20.2133 13.3332 10.6666 22.8798 10.6666 34.6665C10.6666 46.4532 20.2133 55.9998 32 55.9998C43.7866 55.9998 53.3333 46.4532 53.3333 34.6665H48Z"
        fill={fill}
      />
      <path
        d="M29.0667 42.6665V31.2798H28.8267L24.1067 32.9598V34.7998L26.8 33.9731V42.6665H29.0667ZM38.1867 31.4131C37.7067 31.2265 37.2 31.1465 36.6134 31.1465C36.0267 31.1465 35.52 31.2265 35.04 31.4131C34.56 31.5998 34.16 31.8931 33.84 32.2931C33.52 32.6931 33.2267 33.1998 33.0667 33.8132C32.9067 34.4265 32.8 35.1465 32.8 35.9998V37.9731C32.8 38.8265 32.9067 39.5731 33.0934 40.1598C33.28 40.7465 33.5467 41.2798 33.8934 41.6798C34.24 42.0798 34.64 42.3731 35.12 42.5598C35.6 42.7465 36.1067 42.8265 36.6934 42.8265C37.28 42.8265 37.7867 42.7465 38.2667 42.5598C38.7467 42.3731 39.1467 42.0798 39.4667 41.6798C39.7867 41.2798 40.0534 40.7731 40.24 40.1598C40.4267 39.5465 40.5067 38.8265 40.5067 37.9731V35.9998C40.5067 35.1465 40.4 34.3998 40.2134 33.8132C40.0267 33.2265 39.76 32.6931 39.4134 32.2931C39.0667 31.8931 38.64 31.5998 38.1867 31.4131ZM38.2134 38.2665C38.2134 38.7731 38.1867 39.1998 38.1067 39.5465C38.0267 39.8931 37.9467 40.1865 37.8134 40.3998C37.68 40.6131 37.52 40.7731 37.3067 40.8531C37.0934 40.9331 36.88 40.9865 36.64 40.9865C36.4 40.9865 36.16 40.9331 35.9734 40.8531C35.7867 40.7731 35.6 40.6131 35.4667 40.3998C35.3334 40.1865 35.2267 39.8931 35.1467 39.5465C35.0667 39.1998 35.04 38.7731 35.04 38.2665V35.6798C35.04 35.1732 35.0667 34.7465 35.1467 34.3998C35.2267 34.0531 35.3067 33.7865 35.4667 33.5732C35.6267 33.3598 35.76 33.1998 35.9734 33.1198C36.1867 33.0398 36.4 32.9865 36.64 32.9865C36.88 32.9865 37.12 33.0398 37.3067 33.1198C37.4934 33.1998 37.68 33.3598 37.8134 33.5732C37.9467 33.7865 38.0534 34.0531 38.1334 34.3998C38.2134 34.7465 38.24 35.1732 38.24 35.6798V38.2665H38.2134Z"
        fill={fill}
      />
    </svg>
  );
}
