import {API_URL} from '@app/constants';
import {getUserToken} from '@app/stores/auth';
import {imageSize} from '@app/utils/imageSize';
import {useState} from 'react';
import {useSelector} from 'react-redux';

export default function useHistory() {
  const [data, setData] = useState(null);
  const [cursor, setCursor] = useState(null);
  const token = useSelector(getUserToken);

  async function getData() {
    try {
      const response = await fetch(
        `${API_URL}/history?imageType=LANDSCAPE&imageSize=${imageSize(
          imageSize.type.chapter,
        )}`,
        {
          method: 'GET',
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      const result = await response.json();
      setData(result.data);
      setCursor(result.cursor);
      return result;
    } catch (e) {
      console.log('useHistory.error', e);
    }
  }

  return {
    data,
    getData,
    cursor,
  };
}
