import appRoutes from '@app/appRoutes';
import styles from '@app/styles/views/VideoRestricted.module.sass';
import {useLayoutEffect} from 'react';
import {useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import Button from './Button';
import IconLogoFull from './icons/IconLogoFull';
import {focus, keyNavAttribute, keyNavSelectors, KEY_NAV} from './KeyNavItem';
import {useSelector} from 'react-redux';
import {getIsBlockNavItem} from '@app/stores/layout';
import {getUserId} from '@app/stores/auth';
import {ISGLOBAL} from '@app/constants';

export default function VideoRestricted(props) {
  const navigate = useNavigate();
  const ref = useRef();
  const isLayoutRendered = useRef();
  const isBlockNavItem = useSelector(getIsBlockNavItem);
  const userId = useSelector(getUserId);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (!isLayoutRendered.current) {
        if (!isBlockNavItem) {
          let current = ref.current?.querySelector(`[${KEY_NAV.ITEM}]`);
          if (current) {
            isLayoutRendered.current = true;
            focus(current);
          }
        }
      }
    }, 100);
  });

  return (
    <div ref={ref} className={styles.main}>
      <div className={styles.image} />
      <div className={styles.inner} {...keyNavAttribute(KEY_NAV.PARENT)}>
        <IconLogoFull />
        <p className={styles.title}>
          Disfruta todos tus personajes favoritos de{' '}
          <strong className={styles.strong}>hoy y siempre</strong>
        </p>
        <p className={styles.description}>
          {userId
            ? 'Este contenido está disponible solo para suscriptores'
            : 'Inicia sesión para ver este contenido'}
        </p>
        <div className={styles.buttons}>
          {!userId && (
            <Button
              theme={'big'}
              label={'Iniciar sesión'}
              onClick={() =>
                navigate(appRoutes.login() + '?isSuccessBack=true')
              }
              keynavdown={keyNavSelectors().steps(`nextSibling`).build()}
              keynavleft={keyNavSelectors()
                .querySelector(
                  `#menu-main [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                  `#menu-main [${KEY_NAV.ITEM}]`,
                )
                .build()}
            />
          )}
          {!ISGLOBAL && (
            <>
              {!props.isLive && (
                <Button
                  label="Ver señal en vivo gratis"
                  theme={!userId ? 'secundary' : 'big'}
                  onClick={() => navigate(appRoutes.live())}
                  keynavup={keyNavSelectors().steps(`previousSibling`).build()}
                  keynavdown={keyNavSelectors().steps(`nextSibling`).build()}
                  keynavleft={keyNavSelectors()
                    .querySelector(
                      `#menu-main [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                      `#menu-main [${KEY_NAV.ITEM}]`,
                    )
                    .build()}
                />
              )}
            </>
          )}
          <Button
            label="VOLVER AL CAT&Aacute;LOGO"
            theme={'simple'}
            onClick={() => navigate(appRoutes.home())}
            keynavup={keyNavSelectors().steps(`previousSibling`).build()}
            keynavleft={keyNavSelectors()
              .querySelector(
                `#menu-main [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                `#menu-main [${KEY_NAV.ITEM}]`,
              )
              .build()}
          />
        </div>
      </div>
    </div>
  );
}
