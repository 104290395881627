import {Logger} from '@app/logger';
const VOD_URL = 'https://mdstrm.com/video';
const LIVE_URL = 'https://mdstrm.com/live-stream-playlist';

const logger = new Logger('TizenPlayer', true);

const avplay = window?.webapis?.avplay || {
  open(url) {
    logger.log('avplay.open', url);
  },
};

/**
 * MediastreamConfigAds
 * @typedef {Object} MediastreamConfigAds
 * @property {string} map
 */

/**
 * MediastreamConfigAds
 * @typedef {Object} MediastreamConfigEvents
 * @property {function} onPlayerReady
 * @property {function} onPlay
 * @property {function} onVideoEnd
 * @property {function} onVideoStop
 * @property {function} onReplay
 * @property {function} onVideoError
 * @property {function} onTimeUpdate
 * @property {function} onBuffering
 * @property {function} onBufferingStart
 */

/**
 * MediastreamConfigAds
 * @typedef {Object} MediastreamConfig
 * @property {number} width
 * @property {number} height
 * @property {'live'|'media'} type
 * @property {string} id
 * @property {string} access_token
 * @property {MediastreamConfigAds} ads
 * @property {boolean} autoplay
 * @property {boolean} controls
 * @property {number} volume
 * @property {MediastreamConfigEvents} events
 */

class MediastreamPlayer {
  /**
   * @param {string} domId
   * @param {MediastreamConfig} config
   */
  constructor(domId, config) {
    this.domId = domId;
    this.config = config;
    this.$wrap = document.querySelector(`#${domId}`);
    this.STATES = {
      NONE: 'NONE',
      IDLE: 'IDLE',
      READY: 'READY',
      PLAYING: 'PLAYING',
      PAUSED: 'PAUSED',
    };
    this.state = this.STATES.NONE;

    if (this.$wrap) {
      this.setupPlayer();
    }

    logger.log(config);
  }

  setState(state) {
    this.state = state;

    if (this.state === this.STATES.PLAYING) {
      if (typeof this.config?.events?.onPlay === 'function') {
        this.config.events.onPlay();
      }
    } else if (this.state === this.STATES.PAUSED) {
      if (typeof this.config?.events?.onVideoStop === 'function') {
        this.config.events.onVideoStop();
      }
    }
  }

  generateUrl() {
    const p = new URLSearchParams();
    const h = this.config?.type === 'media' ? VOD_URL : LIVE_URL;

    if (this.config?.access_token) {
      p.append('access_token', this.config.access_token);
    }

    return `${h}/${this.config.id}.m3u8?${p.toString()}`;
  }

  setupPlayer() {
    const w = this.config?.width || 600;
    const h = this.config?.height || 400;
    const url = this.generateUrl();
    const $el = document.createElement('object');

    $el.type = 'application/avplayer';
    $el.setAttribute('width', this.config?.width);
    $el.setAttribute('height', this.config?.height);
    this.$el = $el;

    try {
      avplay.open(url);
      this.setState(this.STATES.IDLE);
      avplay.setDisplayRect(0, 0, w, h);
      avplay.prepareAsync(
        () => {
          this.setState(this.STATES.READY);
          if (this.config?.autoplay === true) {
            this.videoPlay();
          }
        },
        e => {
          logger.log('prepareAsync.error', e);
          this.setState(this.STATES.NONE);
        },
      );

      var listener = {
        onbufferingstart: () => {
          if (typeof this.config?.events?.onBufferingStart === 'function') {
            this.config.events.onBufferingStart();
          }
        },

        onbufferingprogress: percent => {
          logger.log('Stream onbufferingprogress', percent);
        },

        onbufferingcomplete: () => {
          logger.log('Stream onbufferingcomplete');
          this.setState(this.STATES.PLAYING);
        },

        onstreamcompleted: () => {
          logger.log('Stream Completed');
          if (typeof this.config?.events?.onVideoEnd === 'function') {
            this.config.events.onVideoEnd();
          }
        },

        oncurrentplaytime: currentTime => {
          if (typeof this.config?.events?.onTimeUpdate === 'function') {
            this.config.events.onTimeUpdate(currentTime / 1000);
          }
        },

        onerror: eventType => {
          logger.log('event type error : ' + eventType);
          if (typeof this.config?.events?.onVideoError === 'function') {
            this.config.events.onVideoError(eventType);
          }
        },

        onevent: (type, data) => {
          logger.log('onevent', type, data);
        },
      };

      avplay.setListener(listener);

      this.$wrap.appendChild($el);
    } catch (e) {
      logger.log('error', e);
      if (typeof this.config?.events?.onVideoError === 'function') {
        this.config.events.onVideoError(e);
      }
    }
  }

  isReady() {
    return avplay.getState() === avplay.STATES.READY;
  }

  isPlaying() {
    return avplay.getState() === avplay.STATES.PLAYING;
  }

  getCurrentTime() {
    return avplay.getCurrentTime();
  }

  videoPlay() {
    try {
      avplay.play();
      this.setState(this.STATES.PLAYING);
    } catch (e) {
      console.log('videoPlay', 'error', e);
    }
  }

  videoStop() {
    try {
      avplay.pause();
      this.setState(this.STATES.PAUSED);
    } catch (e) {
      console.log('videoStop', 'error', e);
    }
  }

  seekTo(point) {
    point = parseInt(point, 10) * 1000;
    avplay.seekTo(point);
  }

  close() {
    try {
      this.$wrap.removeChild(this.$el);
      avplay.close();
    } catch (e) {
      logger.log('close.error', e);
    }
  }
}

let instance;

/**
 * @param {string} domId
 * @param {MediastreamConfig} config
 */
export default function TizenMediastreamPlayer(domId, config) {
  // if (!instance) {
  instance = new MediastreamPlayer(domId, config);
  // }

  return instance;
}
