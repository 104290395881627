import {API_URL_AUTH, AUTH_CLIENT_ID} from '@app/constants';
import {useRef} from 'react';
import {useEffect} from 'react';

const loginUrl = `${API_URL_AUTH}/oauth/device-authorize?client_id=${AUTH_CLIENT_ID}`;

export default function LoginFromRemote({styles, onMessageHandler}) {
  const isRendered = useRef(false);

  function onMessage(e) {
    const data = e?.data;
    if (typeof data === 'string' && typeof onMessageHandler === 'function') {
      onMessageHandler(e?.data);
    }
  }

  useEffect(() => {
    if (!isRendered.current) {
      isRendered.current = true;
      window.addEventListener('message', onMessage);
    }
  }, []);

  return (
    <iframe
      title="login"
      src={loginUrl}
      className={styles.page}
      sandbox="allow-scripts allow-top-navigation allow-forms allow-same-origin"
    />
  );
}
