import {ACTIONS} from '@app/constants';
import {setUserId, trackEvent} from '@app/libs/metrics';
import {ERROR_LOGIN, ERROR_TOKEN_EXPIRED} from '@app/messages';
import {addUserToken} from '@app/stores/auth';
import styles from '@app/styles/views/Login.module.sass';
import Alert from '@components/Alert';
import Button from '@components/Button';
import IconLogoFull from '@components/icons/IconLogoFull';
import jwtDecode from 'jwt-decode';
import {useLocation} from 'react-router-dom';
import {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  focus,
  keyNavAttribute,
  keyNavSelectors,
  KEY_NAV,
} from '@components/KeyNavItem';
import LoginFromRemote from '@components/LoginFromRemote';
import LoginFromLocal from '@components/LoginFromLocal';
import platform from '@app/platform';

export default function Login() {
  const isRendered = useRef(false);
  const [tokenPayload, setTokenPayload] = useState(null);
  const dispatch = useDispatch();
  const params = useLocation();
  const navigate = useNavigate();
  const [theModalAlert, setTheModalAlert] = useState(null);
  const isLayoutRendered = useRef(false);
  const ref = useRef();

  function onMessage(data) {
    try {
      let o = {};
      if (typeof data === 'string') {
        o = JSON.parse(data);
      } else if (data?.access_token) {
        o = data;
      }
      if (o.access_token) {
        let jwt = jwtDecode(o.access_token);
        let now = new Date().getTime();
        let expires_in = o.expires_in * 1000;

        if (now > expires_in) {
          trackEvent({
            action: ACTIONS.LOGIN.LogInFailed,
          });
          setTheModalAlert({
            title: '¡Alerta!',
            body: ERROR_TOKEN_EXPIRED,
            footer: (
              <Button
                label="Aceptar"
                onClick={() => {
                  navigate(-1);
                  setTheModalAlert(null);
                }}
              />
            ),
          });
        } else {
          trackEvent({
            action: ACTIONS.LOGIN.LoginSucces,
          });
          setTokenPayload({
            access_token: o.access_token,
            expires_in: jwt.expira * 1000,
            scope: o.scope,
            email: jwt.email,
            premium: jwt.premium,
            id: jwt.userid,
          });
        }
      } else {
        throw ERROR_LOGIN;
      }
    } catch (error) {
      trackEvent({
        action: ACTIONS.LOGIN.LogInFailed,
      });
      setTheModalAlert({
        title: '¡Alerta!',
        body: ERROR_LOGIN,
        footer: (
          <Button
            label="Aceptar"
            onClick={() => {
              navigate(-1);
              setTheModalAlert(null);
            }}
          />
        ),
      });
    }
  }

  function loginSuccess() {
    if (tokenPayload) {
      dispatch(addUserToken(tokenPayload));
      setUserId(tokenPayload.id);
      if (params?.search.indexOf('isSuccessBack=true') !== -1) {
        navigate(-1);
      }
    }
  }

  useEffect(() => {
    if (!isRendered.current) {
      isRendered.current = true;
      trackEvent({
        action: ACTIONS.LOGIN.LogInShowed,
      });
    }
  }, []);

  useEffect(() => {
    if (tokenPayload) {
      let current = ref.current?.querySelector(`[${KEY_NAV.ITEM}]`);

      if (current) {
        isLayoutRendered.current = true;
        focus(current);
      }
    }
  }, [tokenPayload]);

  return (
    <div ref={ref} className={styles.main}>
      {theModalAlert && (
        <Alert
          title={theModalAlert.title}
          body={theModalAlert.body}
          footer={theModalAlert.footer}
        />
      )}
      {tokenPayload && (
        <div className={styles.message} {...keyNavAttribute(KEY_NAV.PARENT)}>
          <div className={styles.logo}>
            <IconLogoFull />
            <p className={styles.description}>Se inició sesión correctamente</p>
            <Button
              label="Aceptar"
              onClick={loginSuccess}
              keynavleft={keyNavSelectors()
                .querySelector(
                  `#menu-main [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                  `#menu-main [${KEY_NAV.ITEM}]`,
                )
                .build()}
            />
          </div>
        </div>
      )}
      {!tokenPayload && (
        <>
          {platform.isTizen ? (
            <LoginFromLocal styles={styles} onMessageHandler={onMessage} />
          ) : (
            <LoginFromRemote styles={styles} onMessageHandler={onMessage} />
          )}
        </>
      )}
    </div>
  );
}
