import {getUserPremium, getUserToken} from '@app/stores/auth';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {getMediaToken} from './useChapterMediaToken';
import {generateQuery, useGQL} from './useGQL';
import {ISGLOBAL} from '@app/constants';

export default function useLiveToken() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const token = useSelector(getUserToken);
  const userPremium = useSelector(getUserPremium);

  function parseResponse(data) {
    const live = data?.mediaLive?.find(media => {
      return media?.mediaId;
    });
    return live;
  }

  function getQuery() {
    return generateQuery(
      `query liveScheduleToken {
        mediaLive {
          free
          appName
          mediaId
          provider
        }
      }`,
      {},
    );
  }

  const gql = useGQL(parseResponse, getQuery);

  async function getData(payload = {}) {
    setLoading(true);
    setError(null);
    if (!payload) {
      return Promise.reject();
    }
    try {
      const response = await gql.getData(payload);

      let access = false;

      if (userPremium) {
        access = true;
      } else if (ISGLOBAL) {
        access = false;
      } else {
        access = true;
      }

      if (response?.mediaId && access) {
        const mediaToken = await getMediaToken(
          token || (ISGLOBAL ? 'guest_int' : 'guest'),
          response.mediaId,
          'live',
        );

        if (mediaToken?.ok && mediaToken.access_token) {
          response.mediaToken = mediaToken.access_token;
          // } else if (mediaToken?.error) {
          //   setError(mediaToken?.error);
        }
      }

      setData(response);
      return Promise.resolve(response);
    } catch (e) {
      console.log('useLiveScheduleToken.error', e);
      return Promise.reject(e);
    } finally {
      setLoading(false);
    }
  }

  return {
    data,
    loading,
    getData,
    error,
  };
}
