const ADDED = 'ADDED';

const initialState = {
  vastUrl: null,
  minVersion: null,
  latestVersion: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADDED:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

/**
 * addedConfigs.
 *
 * @param {object} payload
 * @param {string?} payload.vastUrl
 * @param {string?} payload.minVersion
 * @param {string?} payload.latestVersion
 */
export function addedConfigs(payload) {
  return async dispatch => {
    const data = {};

    if (payload?.vastUrl) {
      data.vastUrl = payload.vastUrl;
    }
    if (payload?.minVersion) {
      data.minVersion = payload.minVersion;
    }
    if (payload?.latestVersion) {
      data.latestVersion = payload.latestVersion;
    }

    return dispatch({
      type: ADDED,
      payload: data,
    });
  };
}

export function getVastUrl(state) {
  return state?.remoteconfig?.vastUrl;
}

export function getMinVersion(state) {
  return state?.remoteconfig?.minVersion;
}

export function getLatestVersion(state) {
  return state?.remoteconfig?.latestVersion;
}

export function getVersions(state) {
  return {
    minVersion: state?.remoteconfig?.minVersion,
    latestVersion: state?.remoteconfig?.latestVersion,
  };
}
