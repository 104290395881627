const ADD_CURRENT_TIME = 'ADD_CURRENT_TIME';

const initialState = {
  currentTime: 0,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_CURRENT_TIME:
      return {
        ...state,
        currentTime: action.payload,
      };

    default:
      return state;
  }
}

export function addCurrentTime(payload) {
  return async dispatch => {
    return dispatch({
      type: ADD_CURRENT_TIME,
      payload: payload,
    });
  };
}

export function getCurrentTime(state) {
  return state?.player?.currentTime;
}
