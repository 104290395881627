import platform from '@app/platform';
import createStoreAsync from '@app/stores';
import {KEY_NAV, useKeyNavItem} from '@components/KeyNavItem';
import AlertExit from '@views/AlertExit';
import {useCallback, useEffect, useRef, useState} from 'react';
import {Provider} from 'react-redux';
import Root from './Root';

function App() {
  const [stores, setStores] = useState(null);
  const isRendered = useRef(false);
  const keyNav = useKeyNavItem();
  const body = useRef(document.body);
  const [isExitPromp, setExitPromp] = useState(false);

  const onBackHandler = useCallback(() => {
    const hash = window?.location?.hash;
    if (hash === '' || hash === '#/') {
      if (platform.isTizen) {
        setExitPromp(!isExitPromp);
      } else {
        platform.exit();
      }
    } else if (typeof window?.history?.back === 'function') {
      window?.history?.back();
    }
  }, [isExitPromp]);

  function onExitHandler() {
    platform.exit();
  }

  useEffect(() => {
    body.current.addEventListener(KEY_NAV.EVENT_BACK, onBackHandler);
    if (KEY_NAV.EXIT) {
      body.current.addEventListener(KEY_NAV.EXIT, onExitHandler);
    }
    return () => {
      body.current.removeEventListener(KEY_NAV.EVENT_BACK, onBackHandler);
      if (KEY_NAV.EXIT) {
        body.current.removeEventListener(KEY_NAV.EXIT, onExitHandler);
      }
    };
  }, [isExitPromp]);

  useEffect(() => {
    if (!isRendered.current) {
      isRendered.current = true;
      createStoreAsync().then(appStore => {
        setStores(appStore);
      });
      keyNav.start();
    }
  }, []);

  return (
    <>
      {stores && (
        <Provider store={stores}>
          {isExitPromp && (
            <AlertExit
              onNo={() => setExitPromp(false)}
              onYes={() => platform.exit()}
            />
          )}
          {!isExitPromp && <Root />}
        </Provider>
      )}
    </>
  );
}

export default App;
