import styles from '@app/styles/components/TagExclusive.module.sass';
import {ISGLOBAL} from '@app/constants';
import React from 'react';
import {useSelector} from 'react-redux';
import {getUserPremium} from '@app/stores/auth';

/**
 * @param {object} props
 * @param {object} props.styles
 */
export default function TagExclusive(props) {
  const isPremium = useSelector(getUserPremium);
  return (
    <>
      {ISGLOBAL === false && !isPremium && (
        <div className={styles.wrap} style={props.styles}>
          <span className={styles.title}>EXCLUSIVO</span>
        </div>
      )}
    </>
  );
}
