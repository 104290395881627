/**
 * @param {object} props
 * @param {string} props.fill - color
 * @param {number} props.width
 * @param {number} props.height
 */
export default function IconControlPause(props) {
  const fill = props.fill || '#fff';
  const width = props.width || 64;
  const height = props.height || 64;

  return (
    <svg width={width} height={height} viewBox="0 0 64 64" fill="none">
      <path
        d="M24 12H20C18.9391 12 17.9217 12.4214 17.1716 13.1716C16.4214 13.9217 16 14.9391 16 16V48C16 49.0609 16.4214 50.0783 17.1716 50.8284C17.9217 51.5786 18.9391 52 20 52H24C25.0609 52 26.0783 51.5786 26.8284 50.8284C27.5786 50.0783 28 49.0609 28 48V16C28 14.9391 27.5786 13.9217 26.8284 13.1716C26.0783 12.4214 25.0609 12 24 12Z"
        fill={fill}
      />
      <path
        d="M44 12H40C38.9391 12 37.9217 12.4214 37.1716 13.1716C36.4214 13.9217 36 14.9391 36 16V48C36 49.0609 36.4214 50.0783 37.1716 50.8284C37.9217 51.5786 38.9391 52 40 52H44C45.0609 52 46.0783 51.5786 46.8284 50.8284C47.5786 50.0783 48 49.0609 48 48V16C48 14.9391 47.5786 13.9217 46.8284 13.1716C46.0783 12.4214 45.0609 12 44 12Z"
        fill={fill}
      />
    </svg>
  );
}
