import {imageSize} from '@app/utils/imageSize';
import {generateQuery, useGQL} from './useGQL';

/**
 * @typedef {object} getQueryPayload
 * @property {string} imageType
 * @property {string} imageSize
 * @property {string} showSlug
 */

export default function useBannerShow(config) {
  function parseResponse(data) {
    const result = {};
    data?.shows?.node?.forEach(show => {
      result[show?.slug] = show?.images[0]?.url;
    });
    return result;
  }

  /**
   * @param {getQueryPayload} payload
   */
  function getQuery(payload = {}) {
    return generateQuery(
      `query bannersBackground($imageType: ImageTypeEnum!, $imageSize: String!) {
        shows {
          node {
            slug
            images(imageType: $imageType) {
              url(size: $imageSize)
            }
          }
        }
      }`,
      {
        imageType: 'LANDSCAPE',
        imageSize: imageSize(imageSize.type.banner),
        ...payload,
      },
    );
  }
  return useGQL(parseResponse, getQuery, {loading: config?.loading});
}
