/**
 * @param {object} props
 * @param {string} props.fill - color
 * @param {number} props.width
 * @param {number} props.height
 */
export default function IconFavorite(props) {
  const fill = props.fill || '#fff';
  const width = props.width || 20;
  const height = props.width || 20;

  return (
    <svg width={width} height={height} viewBox="0 0 30 28" fill="none">
      <path
        d="M21.058 0C18.5609 0 16.2234 1.13061 14.667 3.02494C13.1106 1.13061 10.773 0 8.27591 0C3.71258 0 0 3.71252 0 8.27592C0 11.8492 2.13068 15.9824 6.33291 20.5605C9.56687 24.0837 13.0848 26.8115 14.0859 27.5636L14.6668 28L15.2476 27.5637C16.2488 26.8115 19.7669 24.0836 23.0008 20.5606C27.2032 15.9825 29.334 11.8493 29.334 8.27592C29.334 3.71252 25.6214 0 21.058 0Z"
        fill={fill}
      />
    </svg>
  );
}
