import styles from '@app/styles/components/ButtonFavorite.module.sass';
import {useEffect, useState} from 'react';
import IconFavorite from './icons/IconFavorite';
import IconFavoriteOutline from './icons/IconFavoriteOutline';
import NavItem, {keyNavProps} from './KeyNavItem';
import Loading from './Loading';

/**
 * @param {object} props
 * @param {function?} props.onClick
 * @param {string|string[]} props.keynavup
 * @param {string|string[]} props.keynavdown
 * @param {string|string[]} props.keynavleft
 * @param {string|string[]} props.keynavright
 * @param {string|null} props.keynavdefault
 * @param {boolean|null} props.loading
 */
export default function ButtonFavorite(props) {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(props.checked || false);
  }, [props.checked]);

  return (
    <NavItem
      id={props?.id}
      {...keyNavProps(props)}
      className={checked ? styles.main_checked : styles.main}
      onClick={props.onClick}>
      <div className={styles.icon}>
        {props.loading && <Loading verytiny={true} dark={true} />}
        {!props.loading && (
          <>
            {checked && <IconFavorite fill={'#FF5000'} />}
            {!checked && <IconFavoriteOutline />}
          </>
        )}
      </div>
    </NavItem>
  );
}
