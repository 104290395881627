export default function IconOff(props) {
  const fill = props.fill || '#fff';
  const width = props.width || 28;
  const height = props.width || 28;

  return (
    <svg width={width} height={height} viewBox="0 0 96 96">
      <g>
        <path
          d="M48,36a5.9966,5.9966,0,0,0,6-6V6A6,6,0,0,0,42,6V30A5.9966,5.9966,0,0,0,48,36Z"
          fill={fill}
        />
        <path
          d="M76.0078,22.9746a6,6,0,0,0-8.0156,8.93A29.39,29.39,0,0,1,78,54a30,30,0,0,1-60,0A29.39,29.39,0,0,1,28.0078,31.9043a6,6,0,0,0-8.0156-8.93,42.0009,42.0009,0,1,0,56.0156,0Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
