import {imageSize} from '@app/utils/imageSize';
import {generateQuery, useGQL} from './useGQL';

/**
 * @typedef {object} Schedule
 * @property {string} startAt
 * @property {string} endAt
 * @property {string} title
 * @property {string} slug
 * @property {string} id
 * @property {object[]} images
 * @property {string} images.url
 */

/**
 * @typedef {object} getQueryPayload
 * @property {string} startAt
 * @property {string} endAt
 * @property {string} imageSize
 * @property {string} imageType
 */

/**
 * @returns {{
 *   data: Schedule[],
 *   getData: function(getQueryPayload): Promise<Schedule[]>
 * }}
 */
export default function useLiveSchedule(config) {
  /**
   * @returns {Schedule[]}
   */
  function parseResponse(data) {
    const result = data?.schedule?.map(item => {
      return {
        startAt: item.startAt * 1000,
        endAt: item.endAt * 1000,
        title: item.title,
        slug: item.slug,
        id: item.slug,
        images: item.images,
      };
    });

    return result;
  }
  /**
   * @param {getQueryPayload} payload
   */
  function getQuery(payload) {
    return generateQuery(
      `query liveSchedule($imageType: ImageTypeEnum!, $imageSize: String!, $startAt: Int!, $endAt: Int!) {
        schedule(startAt: $startAt, endAt: $endAt) {
          title
          slug
          startAt
          endAt
          images(imageType: $imageType){
            url(size: $imageSize)
          }
        }
      }`,
      {
        imageSize: imageSize(imageSize.type.show),
        imageType: 'LANDSCAPE',
        ...payload,
      },
    );
  }
  return useGQL(parseResponse, getQuery, config);
}
