const ADD_BANNERS = 'ADD_BANNERS';

const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_BANNERS:
      return {...action.payload};
    default:
      return state;
  }
}

export function addBanners(payload) {
  return async dispatch => {
    return dispatch({
      type: ADD_BANNERS,
      payload: payload,
    });
  };
}

export function getBanners(state) {
  return state?.banners;
}

export function getBanner(state, slug) {
  return state?.banners[slug];
}
