import {ACTIONS} from '@app/constants';
import {trackEvent} from '@app/libs/metrics';
import styles from '@app/styles/views/Support.module.sass';
import {focus} from '@components/KeyNavItem';
import {useEffect, useRef} from 'react';

export default function Support() {
  const isRendered = useRef(false);

  useEffect(() => {
    if (!isRendered.current) {
      isRendered.current = true;
      trackEvent({
        action: ACTIONS.PROFILE.HelpShowed,
      });
      const current = document.querySelector('#menu-profile');
      if (current) {
        focus(current);
      }
    }
  }, []);

  return (
    <div className={styles.main}>
      <div className={styles.inner}>
        <h1 className={styles.title}>Soporte</h1>
        <p className={styles.description}>
          Puedes escribirnos a:
          <br />
          soportetvgo@americatv.pe
        </p>
      </div>
    </div>
  );
}
