import appRoutes from '@app/appRoutes';
import {ACTIONS, SCREENNAME_PROFILE} from '@app/constants';
import {trackEvent} from '@app/libs/metrics';
import {logoutApi} from '@app/hooks/useProfile';
import {
  getUserId,
  getUserPremium,
  getUserToken,
  logoutUser,
} from '@app/stores/auth';
import styles from '@app/styles/views/Profile.module.sass';
import ButtonArrow from '@components/ButtonArrow';
import {keyNavSelectors, KEY_NAV} from '@components/KeyNavItem';
import Loading from '@components/Loading';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {removeProfile} from '@app/stores/profile';

export default function Profile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const userToken = useSelector(getUserToken);
  const isRendered = useRef(false);
  const userPremium = useSelector(getUserPremium);
  const userId = useSelector(getUserId);

  async function onLogoutHandler() {
    setIsLoading(true);
    try {
      await logoutApi({
        token: userToken,
      });
      dispatch(logoutUser());
      navigate(appRoutes.home());
      dispatch(removeProfile());
      trackEvent({
        action: ACTIONS.PROFILE.logOutClicked,
        label: `${SCREENNAME_PROFILE} - Cerrar Sesion`,
      });
    } catch (e) {
      setIsLoading(true);
      console.log('onLogoutHandler.error', e);
    }
  }

  useEffect(() => {
    if (!isRendered.current) {
      isRendered.current = true;
    }
  }, [userPremium, userId]);

  return (
    <div className={styles.main}>
      {isLoading && <Loading />}
      {!isLoading && (
        <div className={styles.inner}>
          <h1 className={styles.title}>Perfil</h1>
          <div className={styles.buttons}>
            <ButtonArrow
              label={'Cuenta'}
              onClick={() => {
                trackEvent({
                  action: ACTIONS.PROFILE.AccountClicked,
                  label: 'Cuenta',
                });
                navigate(appRoutes.profile('cuenta'));
              }}
              keynavdown={keyNavSelectors().steps('nextSibling').build()}
              keynavleft={keyNavSelectors()
                .querySelector(
                  `#menu-main [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                  `#menu-main [${KEY_NAV.ITEM}]`,
                )
                .build()}
            />
            <ButtonArrow
              label={'Soporte'}
              onClick={() => {
                trackEvent({
                  action: ACTIONS.PROFILE.HelpClicked,
                  label: 'Ayuda',
                });
                navigate(appRoutes.profile('soporte'));
              }}
              keynavup={keyNavSelectors().steps('previousSibling').build()}
              keynavdown={keyNavSelectors().steps('nextSibling').build()}
              keynavleft={keyNavSelectors()
                .querySelector(
                  `#menu-main [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                  `#menu-main [${KEY_NAV.ITEM}]`,
                )
                .build()}
            />
            <ButtonArrow
              label={'App info'}
              onClick={() => {
                trackEvent({
                  action: ACTIONS.PROFILE.AppInfoClicked,
                });
                navigate(appRoutes.profile('appinfo'));
              }}
              keynavup={keyNavSelectors().steps('previousSibling').build()}
              keynavdown={keyNavSelectors().steps('nextSibling').build()}
              keynavleft={keyNavSelectors()
                .querySelector(
                  `#menu-main [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                  `#menu-main [${KEY_NAV.ITEM}]`,
                )
                .build()}
            />
            <ButtonArrow
              label={'Cerrar sesión'}
              onClick={() => {
                onLogoutHandler();
              }}
              keynavup={keyNavSelectors().steps('previousSibling').build()}
              keynavleft={keyNavSelectors()
                .querySelector(
                  `#menu-main [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                  `#menu-main [${KEY_NAV.ITEM}]`,
                )
                .build()}
            />
          </div>
          {/* <p className={styles.version}>Versión: 1.12.2</p> */}
        </div>
      )}
    </div>
  );
}
