import styles from '@app/styles/views/AppInfo.module.sass';
import platform from '@app/platform';
import {useEffect, useRef} from 'react';
import {useUserAddress} from '@app/hooks/useUserAddress';
import Loading from '@components/Loading';

export default function AppInfo() {
  const isRendered = useRef(false);
  const {data: addressInfo, loading, getData} = useUserAddress();

  useEffect(() => {
    if (!isRendered.current) {
      isRendered.current = true;
    }
  }, []);

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={styles.main}>
      {loading && <Loading />}
      {!loading && (
        <div className={styles.inner}>
          <h1 className={styles.title}>App Info</h1>
          <div className={styles.content}>
            <p className={styles.label}>App version: {platform.version}</p>
            <p className={styles.label}>App ID: {platform.bundleId}</p>
            {platform.OSVersion && (
              <p className={styles.label}>OS version: {platform.OSVersion}</p>
            )}
            {platform.firmwareVersion && (
              <p className={styles.label}>
                Firmware version: {platform.firmwareVersion}
              </p>
            )}
            {platform.UID && (
              <p className={styles.label}>UID: {platform.UID}</p>
            )}
            {platform.model && (
              <p className={styles.label}>Modelo: {platform.model}</p>
            )}
            {platform.modelCode && (
              <p className={styles.label}>
                Código de modelo: {platform.modelCode}
              </p>
            )}
            {platform.realModel && (
              <p className={styles.label}>Model real: {platform.realModel}</p>
            )}
            {platform.sdkVersion && (
              <p className={styles.label}>SDK version: {platform.sdkVersion}</p>
            )}
            {platform.brandName && (
              <p className={styles.label}>Marca: {platform.brandName}</p>
            )}
            {addressInfo?.country && (
              <p className={styles.label}>Country: {addressInfo.country}</p>
            )}
            {addressInfo?.ip && (
              <p className={styles.label}>Ip: {addressInfo.ip}</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
