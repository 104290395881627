import platform from '@app/platform';

let $tag;

function createTag(child) {
  if (!$tag) {
    $tag = document.createElement('div');
    $tag.style.position = 'fixed';
    $tag.style.top = '0px';
    $tag.style.right = '0px';
    $tag.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    $tag.style.color = 'white';
    $tag.style.padding = '10px';
    $tag.style.zIndex = 10000;
    $tag.style.overflow = 'auto';
    $tag.style.height = '50%';
    document.body.appendChild($tag);
  }
  $tag.appendChild(child);
  child.scrollIntoView();
}

export class Logger {
  constructor(tag, active) {
    this.tag = tag;
    this.active = false;
  }
  log(...args) {
    if (this.active) {
      console.log(this.tag, ...args);
      if (platform.isTizen) {
        const t = document.createElement('div');
        t.textContent = `${this.tag}: ${JSON.stringify(args)}`;
        createTag(t);
      }
    }
  }
}
