import useProfile from '@app/hooks/useProfile';
import styles from '@app/styles/views/Account.module.sass';
import Button from '@components/Button';
import {KEY_NAV, focus, keyNavSelectors} from '@components/KeyNavItem';
import Loading from '@components/Loading';
import {useNavigate} from 'react-router-dom';
import {useEffect, useRef} from 'react';

export default function Account() {
  const profile = useProfile();
  const isRendered = useRef(false);
  const navigate = useNavigate();
  const ref = useRef();

  useEffect(() => {
    if (!isRendered.current) {
      isRendered.current = true;
      profile.getData();
      const current = document.querySelector('#menu-profile');
      if (current) {
        focus(current);
      }
    }
  }, []);

  useEffect(() => {
    if (profile.data === null) {
      setTimeout(() => {
        const current = ref.current?.querySelector(`[${KEY_NAV.ITEM}]`);
        if (current) {
          focus(current);
        }
      }, 100);
    }
  }, [profile]);

  return (
    <div ref={ref} className={styles.main}>
      {profile.loading && <Loading />}
      {!profile.loading && (
        <div className={styles.inner}>
          <h1 className={styles.title}>Cuenta</h1>
          {profile.data === null && (
            <div className={styles.message}>
              <p>No se pudo obtener la infromacion de la cuenta.</p>
              <Button
                className={styles.messageButton}
                label="Aceptar"
                onClick={() => {
                  navigate(-1);
                }}
                keynavleft={keyNavSelectors()
                  .querySelector(
                    `#menu-main [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                    `#menu-main [${KEY_NAV.ITEM}]`,
                  )
                  .build()}
              />
            </div>
          )}
          {profile.data !== null && (
            <div className={styles.content}>
              <p className={styles.label}>Nombre: {profile.data?.nombres}</p>
              <p className={styles.label}>Correo: {profile.data?.correo}</p>
            </div>
          )}
          <div className={styles.footer}>
            Para modificar tus accesos, dirigete a{' '}
            <a
              href="http://tvgo.americatv.com.pe"
              target="_blank"
              rel="noreferrer">
              tvgo.americatv.com.pe
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
