/**
 * IconHome.
 *
 * @param {object} props
 * @param {number} props.width
 * @param {number} props.height
 * @param {string} props.fill
 */
export default function IconHome(props) {
  const fill = props.fill || '#fff';
  const width = props.width || 32;
  const height = props.height || 28;

  return (
    <svg width={width} height={height} viewBox="0 0 32 28" fill="none">
      <path
        d="M15.9091 0L0 14.8235H4.77272V28H12.7273V20.6631H19.0909V28H27.0455V14.8235H31.8182L15.9091 0Z"
        fill={fill}
      />
    </svg>
  );
}
