/**
 * @param {object} props
 * @param {string} props.fill - color
 * @param {number} props.width
 * @param {number} props.height
 */
export default function IconArrowRight(props) {
  const fill = props.fill || '#fff';
  const width = props.width || 28;
  const height = props.width || 28;

  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none">
      <path
        d="M26.6039 13.569L13.2126 0.177758C13.0993 0.0645407 12.9447 0 12.7828 0H1.82628C1.58039 0 1.35761 0.148541 1.26385 0.376174C1.17009 0.603807 1.22248 0.864366 1.39533 1.03967L14.3569 14L1.39533 26.9603C1.22122 27.1344 1.16889 27.3961 1.26385 27.6238C1.3588 27.8515 1.58039 28 1.82628 28H12.7828C12.9447 28 13.0993 27.9355 13.2138 27.8222L26.6051 14.4309C26.8425 14.1923 26.8425 13.8077 26.6039 13.569Z"
        fill={fill}
      />
    </svg>
  );
}
