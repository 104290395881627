/**
 * @param {object} props
 * @param {string} props.fill - color
 * @param {number} props.width
 * @param {number} props.height
 */
export default function IconSearch(props) {
  const fill = props.fill || '#fff';
  const width = props.width || 29;
  const height = props.height || 30;

  return (
    <svg width={width} height={height} viewBox="0 0 29 30" fill="none">
      <path
        d="M26.9052 27.8254L18.4931 19.5126C20.5978 17.7541 21.9283 15.2567 22.2039 12.5475C22.4795 9.83828 21.6784 7.12931 19.9701 4.99234C18.2618 2.85537 15.7797 1.45758 13.0477 1.09403C10.3157 0.730483 7.54753 1.42962 5.32752 3.04385C3.10751 4.65808 1.60924 7.06112 1.14913 9.74575C0.689021 12.4304 1.30294 15.1866 2.86137 17.4326C4.41979 19.6785 6.80074 21.2386 9.50169 21.7834C12.2026 22.3283 15.0123 21.8153 17.3376 20.3527L25.903 28.8167C26.0378 28.9383 26.215 29.0039 26.3976 28.9998C26.5801 28.9957 26.754 28.9223 26.8831 28.7948C27.0122 28.6672 27.0866 28.4954 27.0907 28.3151C27.0949 28.1349 27.0283 27.9599 26.905 27.8268V27.8254ZM2.44704 11.5061C2.44783 9.70959 2.988 7.95362 3.99907 6.46022C5.01013 4.96682 6.44679 3.80305 8.12742 3.11602C9.80805 2.429 11.6571 2.2496 13.4409 2.60047C15.2248 2.95133 16.8634 3.81674 18.1493 5.08727C19.4353 6.35779 20.3111 7.97638 20.6659 9.73841C21.0206 11.5004 20.8384 13.3268 20.1423 14.9866C19.4463 16.6463 18.2675 18.065 16.7552 19.0632C15.243 20.0614 13.465 20.5943 11.6461 20.5946C9.20683 20.5918 6.86819 19.6333 5.14359 17.9294C3.419 16.2255 2.44915 13.9154 2.44704 11.5061Z"
        fill={fill}
        stroke={fill}
        strokeWidth="2"
      />
    </svg>
  );
}
