import {NavLink} from 'react-router-dom';
import NavItem, {keyNavProps} from './KeyNavItem';

function Wrap(props) {
  if (props.to) {
    return (
      <NavLink
        to={props.to}
        className={({isActive}) =>
          [props.className, isActive ? 'active' : null]
            .filter(Boolean)
            .join(' ')
        }>
        {props.children}
      </NavLink>
    );
  } else {
    return <div className={props.className}>{props.children}</div>;
  }
}

/**
 * MenuItem.
 *
 * @param {object} props
 * @param {string} props.label
 * @param {string} props.to
 * @param {string} props.className
 * @param {Component} props.icon
 * @param {function} props.onClick
 * @param {string|string[]} props.keynavup
 * @param {string|string[]} props.keynavdown
 * @param {string|string[]} props.keynavleft
 * @param {string|string[]} props.keynavright
 * @param {string|null} props.keynavdefault
 */
export default function MenuItem(props) {
  return (
    <NavItem id={props.id} {...keyNavProps(props)} onClick={props.onClick}>
      <Wrap to={props.to} className={props.className}>
        <div className="menu-item">
          <div className="menu-item-mark" />
          <div className="menu-item-icon">{props.icon}</div>
          <div className="menu-item-label">{props.label}</div>
        </div>
      </Wrap>
    </NavItem>
  );
}

export function MenuItemSimple(props) {
  return (
    <div id={props.id} onClick={props.onClick}>
      <Wrap to={props.to} className={props.className}>
        <div className="menu-item">
          <div className="menu-item-mark" />
          <div className="menu-item-icon">{props.icon}</div>
          <div className="menu-item-label">{props.label}</div>
        </div>
      </Wrap>
    </div>
  );
}
