const initialState = {
  data: [],
};

export const SAVE_RESULT = 'SAVE_RESULT';
export const REMOVE_RESULT = 'REMOVE_RESULT';

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_RESULT:
      return {
        data: [...action.payload],
      };
    case REMOVE_RESULT:
      return {
        data: [],
      };
    default:
      return state;
  }
}

export function saveSearchResult(payload) {
  return dispatch => {
    return dispatch({
      type: SAVE_RESULT,
      payload: payload,
    });
  };
}

export function removeSearchResult() {
  return dispatch => {
    return dispatch({
      type: REMOVE_RESULT,
      payload: null,
    });
  };
}

export function getSearchResult(state) {
  return state?.search?.data;
}
