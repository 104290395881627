import styles from '@app/styles/components/SearchBar.module.sass';
import {forwardRef} from 'react';
import {useEffect} from 'react';
import {useRef} from 'react';
import IconSearch from './icons/IconSearch';
import NavItem, {BASE_KEYCODES, keyNavProps} from './KeyNavItem';

/**
 * SearchBar.
 *
 * @param {} props
 */
const SearchBar = forwardRef((props, ref) => {
  const input = useRef();
  const intervalId = useRef(0);
  const lastValue = useRef('');

  function onFocusHandler() {
    if (typeof props?.onFocus === 'function') {
      props.onFocus();
    }
  }

  useEffect(() => {
    intervalId.current = setInterval(() => {
      if (input.current?.value !== lastValue.current) {
        if (typeof props.onChangeText === 'function') {
          props.onChangeText(input.current.value, {});
        }
        lastValue.current = input.current.value;
      }
    }, 1000);

    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  return (
    <NavItem
      ref={ref}
      {...keyNavProps({...props})}
      className={styles.main}
      onMouseEnter={() => input.current?.focus()}>
      <div className={styles.input_container}>
        <div className={styles.btn}>
          <IconSearch />
        </div>
        <input
          ref={input}
          className={styles.input}
          placeholder={'Título, personaje o género'}
          onFocus={onFocusHandler}
          onKeyDown={e => {
            let keyCode = e.keyCode;
            let cursorPos = input.current.selectionStart;
            let textLength = input.current.value.length;

            if (
              (keyCode === BASE_KEYCODES.left && cursorPos > 0) ||
              (keyCode === BASE_KEYCODES.right && cursorPos < textLength)
            ) {
              e.stopPropagation();
            } else if (keyCode === BASE_KEYCODES.left && cursorPos === 0) {
              input.current?.blur();
            }
          }}
          onKeyUp={e => {
            if (e.keyCode === BASE_KEYCODES.down) {
              input.current?.blur();
            }
          }}
        />
      </div>
    </NavItem>
  );
});

export default SearchBar;
