/**
 * @param {object} props
 * @param {string} props.fill - color
 * @param {number} props.width
 * @param {number} props.height
 */
export default function IconFavoriteOutline(props) {
  const fill = props.fill || '#fff';
  const width = props.width || 20;
  const height = props.height || 20;

  return (
    <svg width={width} height={height} viewBox="0 0 40 36" fill="none">
      <path
        d="M20.1284 35.8713C19.6407 35.8713 19.1761 35.6841 18.8224 35.3442C18.7967 35.3197 15.7529 32.3761 3.45823 20.0814L3.37251 19.9957C3.24609 19.8705 3.18408 19.7985 3.12207 19.7259C1.11434 17.5553 0 14.7326 0 11.7952C0 5.36257 5.23367 0.128906 11.6662 0.128906C14.8234 0.128906 17.822 1.39979 19.9994 3.63781C22.1774 1.39968 25.1768 0.128906 28.3339 0.128906C34.7664 0.128906 40.0001 5.36257 40.0001 11.7952C40.0001 15.0186 38.7086 18.0174 36.3623 20.2448C36.3278 20.2849 36.2965 20.3168 36.2734 20.3393C23.8795 32.733 21.5288 35.2465 21.5063 35.2703C21.1613 35.639 20.6774 35.8576 20.1765 35.87L20.1284 35.8713ZM11.6669 1.38164C5.92424 1.38164 1.25273 6.05315 1.25273 11.7958C1.25273 14.417 2.24936 16.9381 4.05868 18.895C4.12006 18.9657 4.16381 19.0171 4.25656 19.1091L4.34417 19.1968C16.6351 31.4878 19.6632 34.4158 19.6901 34.442C19.8085 34.5559 19.9625 34.6186 20.124 34.6198L20.1503 34.6192C20.3137 34.6148 20.4765 34.5416 20.5917 34.4183C20.6098 34.3982 22.9801 31.8647 35.3862 19.4585L35.4763 19.3615C37.5867 17.3713 38.7493 14.6844 38.7493 11.7964C38.7493 6.05378 34.0778 1.38227 28.3351 1.38227C25.307 1.38227 22.4423 2.69134 20.4752 4.97448L20.0007 5.52478L19.5261 4.97385C17.5597 2.69071 14.6956 1.38164 11.6669 1.38164Z"
        fill={fill}
      />
    </svg>
  );
}
