import {fetchApi} from '@app/api/repository';
import {API_URL, ISGLOBAL} from '@app/constants';
import {getUserPremium, getUserToken} from '@app/stores/auth';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {generateQuery, useGQL} from './useGQL';

function isValidVastUrl(metadata) {
  if (metadata?.length > 0 && metadata[0]?.value) {
    return metadata[0]?.value;
  } else {
    return null;
  }
}

export async function getMediaToken(token, mediaId, mediaType) {
  try {
    const response = await fetch(`${API_URL}/mediaToken`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        Authorization: token || (ISGLOBAL ? 'guest_int' : 'guest'),
      },
      body: `media_id=${mediaId}&media_type=${mediaType}`,
    });

    let result = {};

    if (response && typeof response.json === 'function') {
      result = await response.json();
    }

    if (response?.status >= 400 && response?.status <= 500) {
      result.error = `Error ${response.status}`;
      return Promise.resolve({});
    }

    return Promise.resolve(result);
  } catch (e) {
    return Promise.reject(e);
  }
}

/**
 * @returns {Media}
 */
export function parseVODResponse(_data) {
  if (!_data) {
    return null;
  }
  const {chapter, season, show, category} = _data;

  let vastUrl =
    isValidVastUrl(chapter?.metadata) ||
    isValidVastUrl(season?.metadata) ||
    isValidVastUrl(show?.metadata) ||
    isValidVastUrl(category?.metadata) ||
    null;

  const duration = (chapter?.duration || 0) / 1000;

  const trackingTitle = ['VOD'];

  if (show?.title) {
    trackingTitle.push(show.title);
  }

  if (season?.title) {
    trackingTitle.push(season.title);
  }

  if (chapter?.title) {
    trackingTitle.push(chapter.title);
  }

  const result = {
    trackingTitle: trackingTitle.join(' - '),
    category: {
      slug: category?.slug,
      title: category?.title,
    },
    showData: {
      broadcast: show?.broadcast,
      slug: show?.slug,
      title: show?.title,
    },
    seasonData: {
      slug: season?.slug,
      title: season?.title,
    },
    chapterData: {
      free: chapter?.free,
      slug: chapter?.slug,
      title: chapter?.title,
      duration: duration,
    },
    videoData: {},
    vastUrl,
    duration,
  };

  return result;
}

/** @typedef {object} Media
 */

/**
 * @returns {Media}
 */
export function parseResponse(_data) {
  return _data
    ? parseVODResponse({
        category: _data?.category,
        show: _data?.show,
        season: _data?.season,
        chapter: _data?.chapter,
        media: null,
      })
    : null;
}

/**
 * @param {{
 *   categorySlug: string,
 *   showSlug: string,
 *   seasonSlug: string,
 *   chapterSlug: string,
 *   metadataType?: string,
 * }} payload
 */
function getQuery(payload) {
  return generateQuery(
    `query chapterMediaToken($categorySlug: String!, $showSlug: String!, $seasonSlug: String!, $chapterSlug: String!, $metadataType: MetadataTypeEnum!) {
      category(slug: $categorySlug) {
        title
        slug
        metadata(metadataType: $metadataType) {
          value
        }
      }
      show(slug: $showSlug) {
        title
        slug
        broadcast
        categorySlug
        metadata(metadataType: $metadataType) {
          value
        }
      }
      season(slug: $seasonSlug) {
        title
        slug
        metadata(metadataType: $metadataType) {
          value
        }
      }
      chapter(slug: $chapterSlug) {
        free
        slug
        title
        metadata(metadataType: $metadataType) {
          value
        }
        duration
      }
    }`,
    {
      metadataType: 'VAST_ANDROID',
      ...payload,
    },
  );
}

function getQueryMediaId(payload) {
  return generateQuery(
    `query chapterMediaId($chapterSlug: String!, $mediaProvider: MediaProviderEnum!) {
      chapter(slug: $chapterSlug) {
        media(provider: $mediaProvider) {
          mediaId
        }
      }
    }`,
    {
      mediaProvider: 'MEDIASTREAM',
      ...payload,
    },
  );
}

function parseResponseMediaId(_data) {
  return _data?.chapter?.media[0] || null;
}

/**
 * @returns {{
 *   getData: function({
 *     showSlug: string,
 *     seasonSlug: string,
 *     chapterSlug: string,
 *   } | {
 *     videoData: {
 *      mediaId: string,
 *      mediaToken?: string,
 *      user_progress_seconds?: number,
 *      user_progress_percentage?: number
 *    }
 *   }): Promise<Media>,
 *   data: Media
 * }}
 */
export default function useChapterMediaToken() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const token = useSelector(getUserToken);
  const userPremium = useSelector(getUserPremium);

  const gql = useGQL(parseResponse, getQuery);
  const gqlMediaId = useGQL(parseResponseMediaId, getQueryMediaId);

  async function getData(payload) {
    setLoading(true);
    setError(null);

    if (!payload || !token) {
      setError(true);
      setLoading(false);
      return Promise.reject();
    }

    try {
      let response = {};
      let media = null;

      if (payload?.videoData?.mediaId) {
        response = payload;
      } else {
        media = await gqlMediaId.getData({
          chapterSlug: payload.chapterSlug,
        });
        response = await gql.getData(payload);
        response.chapterData.media = media;
        response.videoData.mediaId = media?.mediaId;
      }

      let access = false;

      if (userPremium) {
        access = true;
      } else if (ISGLOBAL) {
        access = false;
      } else {
        access = token && response?.chapterData?.free === true;
      }

      if (response?.videoData?.mediaId && access) {
        const mediaToken = await getMediaToken(
          token,
          response?.videoData?.mediaId,
          'vod',
        );

        if (mediaToken?.ok && mediaToken?.access_token) {
          response.videoData.mediaToken = mediaToken.access_token;
          response.videoData.user_progress_seconds =
            mediaToken.user_progress_seconds;
          response.videoData.user_progress_percentage =
            mediaToken.user_progress_percentage;
        }
      }
      setData(response);
      return Promise.resolve(response);
    } catch (e) {
      setError(e);
      return Promise.reject(e);
    } finally {
      setLoading(false);
    }
  }

  return {
    data,
    loading,
    getData,
    error,
  };
}

export async function getChapterMedia(payload) {
  try {
    const response = await fetchApi(getQuery(payload));
    const result = parseResponse(await response.json());
    return Promise.resolve(result);
  } catch (e) {
    return Promise.reject(e);
  }
}
