import styles from '@app/styles/components/EpisodethumbExtend.module.sass';
import NavItem, {keyNavProps} from './KeyNavItem';
import TagExclusive from './TagExclusive';
import TagFree from './TagFree';

/**
 * EpisodethumbExtend.
 *
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.slug
 * @param {string} props.image
 * @param {string} props.description
 * @param {object} props.season
 * @param {string} props.season.slug
 * @param {string} props.season.title
 * @param {object} props.show
 * @param {string} props.show.slug
 * @param {string} props.show.title
 * @param {object} props.media
 * @param {object} props.media.views
 * @param {object} props.media.mediaId
 * @param {object} props.media.quality
 * @param {object} props.media.duration
 * @param {object} props.media.provider
 * @param {function} props.onFocus
 * @param {string|string[]} props.keynavup
 * @param {string|string[]} props.keynavdown
 * @param {string|string[]} props.keynavleft
 * @param {string|string[]} props.keynavright
 */
export default function EpisodethumbExtend(props) {
  return (
    <NavItem
      {...keyNavProps(props)}
      className={styles.wrap}
      onClick={e => {
        if (typeof props.onClick === 'function') {
          props.onClick(e);
        }
      }}
      onMouseEnter={e => {
        if (typeof props.onFocus === 'function') {
          props.onFocus(e);
        }
      }}>
      <div className={styles.main}>
        <div
          className={styles.picture}
          style={{
            backgroundImage: `url(${props.image})`,
          }}
        />
        {props.free !== undefined && props.free === true && (
          <TagFree styles={{position: 'absolute', left: 4, bottom: 4}} />
        )}
        {props.free !== undefined && props.free === false && (
          <TagExclusive styles={{position: 'absolute', left: 4, bottom: 4}} />
        )}
      </div>
      <div className={styles.secundary}>
        <p className={styles.title}>{props.title}</p>
        <p className={styles.description}>{props.description}</p>
      </div>
    </NavItem>
  );
}
