import styles from '@app/styles/views/AlertExit.module.sass';
import Button from '@components/Button';
import {KEY_NAV, focus, keyNavAttribute} from '@components/KeyNavItem';
import IconLogoFull from '@components/icons/IconLogoFull';
import {useEffect, useRef} from 'react';

export default function AlertExit(props) {
  const isLayoutRendered = useRef(false);
  const ref = useRef();

  function noHandler() {
    if (typeof props.onNo === 'function') {
      props.onNo();
    }
  }

  function yesHandler() {
    if (typeof props.onYes === 'function') {
      props.onYes();
    }
  }

  useEffect(() => {
    if (!isLayoutRendered.current) {
      let current = ref.current?.querySelector(`[${KEY_NAV.ITEM}]`);

      if (current) {
        isLayoutRendered.current = true;
        focus(current);
      }
    }
  }, []);

  return (
    <div ref={ref} className={styles.main}>
      <div className={styles.info}>
        <div className={styles.logo}>
          <IconLogoFull />
        </div>
        <p className={styles.description}>¿Deseas salir del aplicativo?</p>
        <div className={styles.buttons} {...keyNavAttribute(KEY_NAV.PARENT)}>
          <Button label="No" onClick={noHandler} />
          <Button label="Si" onClick={yesHandler} theme="simple" />
        </div>
      </div>
    </div>
  );
}
