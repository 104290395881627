import styles from '@app/styles/components/ButtonArrow.module.sass';
import IconArrowRight from './icons/IconArrowRight';
import NavItem, {keyNavProps} from './KeyNavItem';

/**
 * ButtonArrow.
 *
 * @param {object} props
 * @param {string} props.label
 * @param {function} props.onClick
 */
export default function ButtonArrow(props) {
  return (
    <NavItem
      id={props.id}
      {...keyNavProps(props)}
      className={styles.main}
      onClick={props.onClick}>
      <div className={styles.label}>{props.label}</div>
      <div className={styles.icon}>
        <IconArrowRight />
      </div>
    </NavItem>
  );
}
