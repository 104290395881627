import {ISGLOBAL} from '@app/constants';
import {initializeApp} from 'firebase/app';

let firebaseConfig = {
  apiKey: 'AIzaSyDyGy1yMUzJvoMJJH96Ab5N0v4Ehwp7TJQ',
  authDomain: 'tvgo-ef5d2.firebaseapp.com',
  databaseURL: 'https://tvgo-ef5d2.firebaseio.com',
  projectId: 'tvgo-ef5d2',
  storageBucket: 'tvgo-ef5d2.appspot.com',
  messagingSenderId: '798950752151',
  appId: '1:798950752151:web:ce2722e93a0ff2f361ced2',
  measurementId: 'G-9LNQC05JQR',
};

if (ISGLOBAL) {
  firebaseConfig = {
    apiKey: 'AIzaSyBpVD6CB96xBJyg_96h8r5LEtDkl7xG_yk',
    authDomain: 'tvgo-extr.firebaseapp.com',
    databaseURL: 'https://tvgo-extr.firebaseio.com',
    projectId: 'tvgo-extr',
    storageBucket: 'tvgo-extr.appspot.com',
    messagingSenderId: '421497778388',
    appId: '1:421497778388:web:b571dd08655afb0b434b41',
    measurementId: 'G-83SEESCL0N',
  };
}

export const app = initializeApp(firebaseConfig);
