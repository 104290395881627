import styles from '@app/styles/components/SearchBar.module.sass';
import {forwardRef} from 'react';
import {useEffect} from 'react';
import {useRef} from 'react';
import IconSearch from './icons/IconSearch';
import NavItem, {BASE_KEYCODES, keyNavProps} from './KeyNavItem';

/**
 * SearchBar.
 *
 * @param {} props
 */
const SearchBarSimple = forwardRef((props, ref) => {
  const input = useRef();
  const intervalId = useRef(0);
  const lastValue = useRef('');
  const isInputFocus = useRef(false);

  function onFocusHandler() {
    if (typeof props?.onFocus === 'function') {
      props.onFocus();
    }
  }

  function focusInput() {
    console.log('focusInput', input.current);
    if (input.current) {
      isInputFocus.current = true;
      input.current.focus();
      onFocusHandler();
    }
  }

  function blurInput() {
    console.log('blurInput', input.current);
    if (input.current) {
      isInputFocus.current = false;
      input.current.blur();
    }
  }

  function onKeyDown(e) {
    const keyCode = e.keyCode;

    if (isInputFocus.current) {
      e.stopPropagation();
    }

    switch (keyCode) {
      case BASE_KEYCODES.enter:
      case BASE_KEYCODES.back:
      case BASE_KEYCODES.done:
      case BASE_KEYCODES.cancel:
        blurInput();
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    intervalId.current = setInterval(() => {
      if (input.current?.value !== lastValue.current) {
        if (typeof props.onChangeText === 'function') {
          props.onChangeText(input.current.value, {});
        }
        lastValue.current = input.current.value;
      }
    }, 1000);

    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  useEffect(() => {
    input.current?.addEventListener('keydown', onKeyDown);

    return () => {
      input.current?.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  return (
    <NavItem
      ref={ref}
      {...keyNavProps({...props})}
      onClick={focusInput}
      className={styles.main}>
      <div className={styles.input_container}>
        <div className={styles.btn}>
          <IconSearch />
        </div>
        <input
          ref={input}
          className={styles.input}
          placeholder={'Título, personaje o género'}
        />
      </div>
    </NavItem>
  );
});

export default SearchBarSimple;
