/**
 * @param {object} props
 * @param {string} props.fill - color
 * @param {number} props.width
 * @param {number} props.height
 */
export default function IconPlay(props) {
  const fill = props.fill || '#fff';
  const width = props.width || 20;
  const height = props.height || 20;

  return (
    <svg width={width} height={height} viewBox="0 0 24 26" fill="none">
      <path
        d="M4.53083 0.57328C2.18678 -0.771299 0.286377 0.330196 0.286377 3.03157V22.9665C0.286377 25.6706 2.18678 26.7707 4.53083 25.4274L21.955 15.4347C24.2998 14.0897 24.2998 11.9105 21.955 10.5657L4.53083 0.57328Z"
        fill={fill}
      />
    </svg>
  );
}
