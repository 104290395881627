import {Logger} from '@app/logger';
import platform from '@app/platform';
import {
  actions_player,
  actions_page,
  actions_login,
  actions_paywall,
  actions_profile,
} from '@app/constants';
let metrics = {};
if (platform.isTizen) {
  metrics = {
    logEvent: () => {},
    setUserId: () => {},
    setCurrentScreen: () => {},
  };
} else {
  metrics = require('firebase/analytics');
  metrics.analytics = metrics.getAnalytics(
    require('@app/libs/firebase').app.app,
  );
}
const logger = new Logger('metrics', true);

/**
 * @param {string} action
 * @param {{[key:string]: string}} meta
 */
function logEvent(action, meta) {
  return metrics.logEvent(metrics.analytics, action, meta);
}

/**
 * @param {string} id
 */
function analyticsSetUserId(id) {
  return metrics.setUserId(metrics.analytics, id);
}

/**
 * @param {string} screenName
 */
function setCurrentScreen(screenName) {
  return metrics.setCurrentScreen(metrics.analytics, screenName);
}

/**
 * trackEvent.
 *
 * @param {object} payload
 * @param {string} payload.label
 * @param {string} payload.action
 * @param {string} payload.category
 */
export async function trackEvent(payload) {
  try {
    /**
     * @type {{
     *  label: string,
     *  action: string,
     *  category: string,
     * }}
     */
    let meta = {};
    let category = '';

    if (actions_player.indexOf(payload.action) !== -1) {
      category = 'Player';
    } else if (actions_page.indexOf(payload.action) !== -1) {
      category = 'Page';
    } else if (actions_login.indexOf(payload.action) !== -1) {
      category = 'Login';
    } else if (actions_paywall.indexOf(payload.action) !== -1) {
      category = 'Paywall';
    } else if (actions_profile.indexOf(payload.action) !== -1) {
      category = 'Profile';
    }

    if (payload.label) {
      meta.label = payload.label;
    }

    payload.category = payload.category || category;

    meta = {
      action: payload.action,
      category: payload.category,
    };

    if (payload.label) {
      meta.label = payload.label;
    }

    logEvent(payload.action, meta);

    logger.log('tracking.trackEvent', payload);
  } catch (error) {
    logger.log('tracking.trackEvent', error);
  }
}

/**
 * setUserId.
 *
 * @param {string} id
 */
export async function setUserId(id) {
  try {
    analyticsSetUserId(id);
    logger.log('tracking.setUserId', id);
  } catch (error) {
    logger.log('tracking.setUserId', error);
  }
}

/**
 * @trackScreenView
 *
 * @param {string} screenName
 * @param {string} userId
 */
export async function trackScreenView(screenName, userId) {
  let customDimensions = {};

  try {
    if (userId) {
      customDimensions.userId = userId;
      setCurrentScreen(screenName);
      logger.log('tracking.trackScreenView', screenName, {customDimensions});
    } else {
      logger.log('tracking.trackScreenView', screenName);
    }
  } catch (error) {
    logger.log('tracking.trackScreenView', error);
  }
}

/**
 * @setUserProperties
 *
 * @param {{[key:string]: string}} payload
 */
export async function setUserProperties(payload) {
  try {
    metrics.setUserProperties(metrics.analytics, payload);
    logger.log('tracking.setUserProperties', payload);
  } catch (e) {
    logger.log('tracking.setUserProperties', e);
  }
}
