import {combineReducers, configureStore} from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import auth from './auth';
import profile from './profile';
import search from './search';
import shows from './shows';
import schedules from './schedule';
import banners from './banners';
import layout from './layout';
import player from './player';
import popup from './popup';

const STORE_ID = 'tvGOAPPSTATE';

let timeout_id;

let store;

const reducers = combineReducers({
  auth,
  profile,
  search,
  shows,
  schedules,
  banners,
  layout,
  player,
  popup,
});

function _createStore(data) {
  store = configureStore({
    reducer: reducers,
    preloadedState: data || {},
    middleware: getDefaultMiddleware => getDefaultMiddleware({}).concat(thunk),
    devTools: true,
  });

  store.subscribe(() => {
    clearTimeout(timeout_id);
    setTimeout(changeStateHandler, 1000);
  });

  return store;
}

async function createStoreAsync() {
  if (!store) {
    let response = {};
    try {
      response = JSON.parse(localStorage.getItem(STORE_ID));
    } catch (e) {
      console.log('createStoreAsync', e);
    }

    return Promise.resolve(_createStore(response));
  } else {
    return Promise.resolve(store);
  }
}

function changeStateHandler() {
  let data;

  if (store && typeof store?.getState === 'function') {
    data = store.getState();

    localStorage.setItem(
      STORE_ID,
      JSON.stringify({
        auth: data.auth,
        profile: data.profile,
        search: data.search,
      }),
    );
  }
}

export default createStoreAsync;
