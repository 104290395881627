import styles from '@app/styles/components/BannerMain.module.sass';
import {useState} from 'react';
import {useEffect} from 'react';

/**
 * BannerMain.
 *
 * @param {object} props
 * @param {string} props.source
 */
export default function BannerMain(props) {
  const [h, setH] = useState(window.innerWidth / 1.6);

  useEffect(() => {
    function onResize() {
      const w = window.innerWidth;
      const h = w / 1.6;
      setH(h);
    }
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <div className={styles.main} style={{height: h}}>
      <div className={styles.shadow} />
      <div
        className={styles.picture}
        style={{
          backgroundImage: `url(${props.source})`,
        }}
      />
    </div>
  );
}
