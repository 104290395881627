import moment from 'moment';

const ADD_SCHEDULES = 'ADD_SCHEDULES';

const initialState = {
  schedules: [],
  cursor: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_SCHEDULES:
      return {
        schedules: [...(action.payload?.schedules || [])],
        cursor: action.payload?.cursor,
      };
    default:
      return state;
  }
}

/**
 * @param {object} payload
 * @param {object[]} payload.schedules
 * @param {string} payload.cursor
 */
export function addSchedules(payload) {
  return async dispatch => {
    return dispatch({
      type: ADD_SCHEDULES,
      payload: payload,
    });
  };
}

export function getSchedules(state) {
  return state?.schedules?.schedules;
}

export function getScheduleCursor(state) {
  return state?.schedules?.cursor;
}

export function getSchedulesPending(schedules, startAt) {
  const data = schedules || [];
  const result = [];
  const rigthNow = moment(startAt || new Date()).utcOffset(-5);

  data.forEach(item => {
    const startAt = moment(item?.startAt).utcOffset(-5);

    if (!rigthNow.isAfter(startAt)) {
      result.push(item);
    }
  });

  return result;
}

export function getScheduleLive(schedules, startAt) {
  const data = schedules || [];
  const _now = moment(startAt || new Date())
    .utcOffset(-5)
    .valueOf();
  let i = 0;
  let l = data.length;
  let item = {};
  let current_show = {};

  for (; i < l; ++i) {
    item = data[i];
    if (
      (_now >= item.startAt && _now < item.endAt) ||
      (_now > item.startAt && _now < item.endAt)
    ) {
      current_show = item;
      break;
    }
  }

  return current_show;
}
