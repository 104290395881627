import {fetchApi} from '@app/api/repository';
import {API_URL} from '@app/constants';
import {useState} from 'react';

export async function getInfo() {
  try {
    const url = `${API_URL}/viewer-info`;
    const params = {
      headers: {
        'Content-type': 'application/json',
      },
    };

    const response = await fetchApi(url, params);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log('getInfo', error);
    return null;
  }
}

export function useUserAddress() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  async function getData() {
    setLoading(true);
    try {
      const response = await getInfo();
      setData(response);
      return Promise.resolve(response);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      setLoading(false);
    }
  }

  return {
    loading,
    data,
    getData,
  };
}
