import {NavLink} from 'react-router-dom';
import NavItem, {keyNavProps} from './KeyNavItem';

function Wrap(props) {
  if (props.to) {
    return (
      <NavLink
        to={props.to}
        className={({isActive}) =>
          [isActive ? 'active' : null].filter(Boolean).join(' ')
        }>
        {props.children}
      </NavLink>
    );
  } else {
    return <>{props.children}</>;
  }
}

/**
 * MenuSubItem.
 *
 * @param {object} props
 * @param {string} props.label
 * @param {string} props.to
 * @param {function} props.onClick
 */
export default function MenuSubItem(props) {
  return (
    <Wrap to={props.to}>
      <NavItem
        {...keyNavProps(props)}
        className="menu-sub-item"
        onClick={props.onClick}
        onMouseLeave={props.onMouseLeave}>
        <div className="menu-sub-item-label">{props.label}</div>
        <div className="menu-sub-item-mark" />
      </NavItem>
    </Wrap>
  );
}
