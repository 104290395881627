import {imageSize} from '@app/utils/imageSize';
import {generateQuery, useGQL} from './useGQL';

/**
 * @typedef {object} Show
 * @property {string} title
 * @property {string} slug
 * @property {object[]} images
 * @property {string} images.url
 * @property {object} category
 * @property {string} category.title
 * @property {string} category.slug
 * @property {string} category.render
 * @property {object} category.metadata
 * @property {string} category.metadata.value
 */

/**
 * @typedef {object} getDataPayload
 * @property {string} imageType
 * @property {string} imageSize
 * @property {string} search
 */

/**
 * @returns {{
 *   data: Show[],
 *   getData: function(getDataPayload): Promise<Show[]>
 * }}
 */
export default function useSearch() {
  function parseResponse(data) {
    const result = [];
    data?.categories?.node?.forEach(category => {
      category?.shows?.node?.forEach(show => {
        result.push({
          title: show?.title,
          slug: show?.slug,
          images: show?.images,
          category: {
            title: category?.title,
            slug: category?.slug,
            render: category?.render,
            metadata: category?.metadata,
          },
        });
      });
    });
    return result;
  }

  /**
   * @param {getDataPayload} payload
   * @returns {string}
   */
  function getQuery(payload) {
    return generateQuery(
      `query searchBasic($imageType: ImageTypeEnum!, $imageSize: String!, $search: String!, $metadataType: MetadataTypeEnum!) {
        categories {
          node {
            title
            slug
            render
            metadata(metadataType: $metadataType) {
              value
            }
            shows(search: $search) {
              node {
                title
                slug
                images(imageType: $imageType){
                  url(size: $imageSize)
                }
              }
            }
          }
        }
      }`,
      {
        imageType: 'LANDSCAPE',
        imageSize: imageSize(imageSize.type.show),
        metadataType: 'VAST_ANDROID',
        ...payload,
      },
    );
  }

  return useGQL(parseResponse, getQuery, {
    loading: false,
  });
}
