import {forwardRef} from 'react';
import {useImperativeHandle} from 'react';
import {useEffect} from 'react';
import {useRef} from 'react';
import platform from '@app/platform';

/**
 * @typedef {object} NavItemProps
 * @property {string|string[]} keynavup
 * @property {string|string[]} keynavup
 * @property {string|string[]} keynavdown
 * @property {string|string[]} keynavleft
 * @property {string|string[]} keynavright
 * @property {string|string[]} keynavscrolltotop
 * @property {string|string[]} noscrollvertical
 * @property {string|string[]} noscrollhorizontal
 * @property {string|null} keynavdefault
 */

export const KEY_NAV = {
  ITEM: 'keynavitem',
  ITEM_FOCUS: 'keynavitemfocus',
  ITEM_LAST_FOCUS: 'keynavitemlastfocus',
  SCROLL_DOWN: 'keynavscrolldown',
  PARENT: 'keynavparent',
  CONTENT: 'keynavcontent',
  PARENT_LAST_FOCUS: 'keynavparentlastfocus',
  EVENT_FOCUS: 'keyFocus',
  EVENT_BLUR: 'keyBlur',
  EVENT_PARENT_BLUR: 'keyParentBlur',
  EVENT_PLAY: 'keyplay',
  EVENT_PAUSE: 'keypause',
  EVENT_PLAY_PAUSE: 'keyplaypause',
  EVENT_STOP: 'keystop',
  EVENT_FWD: 'keyfwd',
  EVENT_REWIND: 'keyrewind',
  EVENT_BACK: 'keyback',
};

export const BASE_KEYCODES = {
  ...platform.KEYCODES,
};

export function keyNavAttribute() {
  const attrs = {};
  let i = null;
  for (i of arguments) {
    attrs[i] = i;
  }
  return attrs;
}

const PROPS = [
  'keynavdefault',
  'keynavdown',
  'keynavup',
  'keynavleft',
  'keynavright',
  'keynavscrolltotop',
  'noscrollvertical',
  'noscrollhorizontal',
  'keynavitemlastfocus',
  'keynavtype',
];

export function keyNavProps(props) {
  let p = {};
  Object.keys(props).forEach(key => {
    if (PROPS.indexOf(key) !== -1) {
      p[key] = props[key];
    }
  });

  return p;
}

const keyNavSelectorsCommandNames = {
  onlyIndex0: '$0',
  querySelector: '$1',
  steps: '$2',
  relativeQuerySteps: '$3',
  relativeNthChildDown: '$4',
  relativeNthChildUp: '$5',
  borderLeft: '$6',
  borderRight: '$7',
  onlyFirstRow: '$8',
};
export const keyNavSelectors = function () {
  const self = {
    c: [],
    block_separator: '|',
    command_separator: '/',
    arguments_separator: ';',
    commands_name: keyNavSelectorsCommandNames,
    create: function (command, ..._args) {
      const command_name = self.commands_name[command];
      const args = Array.from(_args).join(self.arguments_separator);
      self.c.push(command_name + self.command_separator + args);
      return self;
    },
    onlyIndex0: function () {
      self.create('onlyIndex0', ...arguments);
      return self;
    },
    querySelector: function () {
      self.create('querySelector', ...arguments);
      return self;
    },
    steps: function () {
      self.create('steps', ...arguments);
      return self;
    },
    relativeQuerySteps: function () {
      self.create('relativeQuerySteps', ...arguments);
      return self;
    },
    relativeNthChildDown: function () {
      self.create('relativeNthChildDown', ...arguments);
      return self;
    },
    relativeNthChildUp: function () {
      self.create('relativeNthChildUp', ...arguments);
      return self;
    },
    borderLeft: function () {
      self.create('borderLeft', ...arguments);
      return self;
    },
    borderRight: function () {
      self.create('borderRight', ...arguments);
      return self;
    },
    onlyFirstRow: function () {
      self.create('onlyFirstRow', ...arguments);
      return self;
    },
    build: function () {
      return self.c.join(self.block_separator);
    },
    parse: function (cmd) {
      let result = [];
      let blocks = cmd.split(self.block_separator);
      for (const b of blocks) {
        const command = b.split(self.command_separator);
        command[1] = command[1]?.split(self.arguments_separator);
        result.push([command[0], ...command[1]]);
      }
      return result;
    },
  };
  return self;
};

keyNavSelectors.commandNames = keyNavSelectorsCommandNames;

function removeFocusAll() {
  document.querySelectorAll(`[${KEY_NAV.ITEM_FOCUS}]`)?.forEach(item => {
    if (typeof item?.removeAttribute === 'function') {
      item.removeAttribute(KEY_NAV.ITEM_FOCUS);
    }
  });
}

/**
 * @param {HTMLElement} el
 * @param {object|null} config
 * @param {boolean|null} config.mouse
 */
export function focus(el, config) {
  if (typeof el?.setAttribute === 'function') {
    let parent = el.closest(`[${KEY_NAV.PARENT}]`);
    if (!parent) {
      parent = el.parentNode;
    }
    const prevCurrent = parent.querySelector(`[${KEY_NAV.ITEM_FOCUS}]`);

    const theContent = el.closest(`[${KEY_NAV.CONTENT}]`);

    removeFocusAll();

    if (typeof el?.dispatchEvent === 'function') {
      el.dispatchEvent(
        new Event(KEY_NAV.EVENT_FOCUS, {
          bubbles: true,
          cancelable: false,
        }),
      );
    }

    if (prevCurrent) {
      prevCurrent.removeAttribute(KEY_NAV.ITEM_LAST_FOCUS);

      if (typeof prevCurrent?.dispatchEvent === 'function') {
        prevCurrent.dispatchEvent(
          new Event(KEY_NAV.EVENT_BLUR, {
            bubbles: true,
            cancelable: false,
          }),
        );
      }
    }

    el.setAttribute(KEY_NAV.ITEM_FOCUS, KEY_NAV.ITEM_FOCUS);
    el.setAttribute(KEY_NAV.ITEM_LAST_FOCUS, KEY_NAV.ITEM_LAST_FOCUS);

    if (theContent) {
      theContent
        .querySelectorAll(`[${KEY_NAV.PARENT_LAST_FOCUS}]`)
        ?.forEach(item => {
          if (typeof item?.removeAttribute === 'function' && item !== parent) {
            item.removeAttribute(KEY_NAV.PARENT_LAST_FOCUS);
            item.dispatchEvent(
              new Event(KEY_NAV.EVENT_PARENT_BLUR, {
                bubbles: true,
                cancelable: false,
              }),
            );
          }
        });
    }

    parent?.setAttribute(KEY_NAV.PARENT_LAST_FOCUS, KEY_NAV.PARENT_LAST_FOCUS);

    if (!config?.mouse) {
      if (!el.getAttribute('noscrollhorizontal')) {
        if (typeof parent?.scrollTo === 'function') {
          parent.scrollTo({
            behavior: 'smooth',
            left: el.clientWidth * Array.from(parent.children).indexOf(el),
          });
        }
      }

      if (!el.getAttribute('noscrollvertical')) {
        let scroll_down = el.closest(`[${KEY_NAV.SCROLL_DOWN}]`);
        if (typeof scroll_down?.scrollTo === 'function') {
          scroll_down.scrollTo({
            behavior: 'smooth',
            top: el.getAttribute('keynavscrolltotop') ? 0 : el.offsetTop - 90,
          });
        }
      }
    }
  }
}

/**
 * @param {HTMLElement} current
 * @param {string} _args
 * @returns {HTMLElement|null}
 */
function parseArgs(current, _args) {
  if (!current || !_args || _args?.length === 0) {
    return null;
  }

  const args = keyNavSelectors().parse(_args);
  let target = null;
  let preTarget = null;
  let tmp = null;
  let cmd = null;
  let cmdArgs = null;
  let currentIndex = -1;
  let currentWidth = 0;
  let parent = null;
  let parentWidth = 0;
  let columns = 0;
  let isBorder = false;
  let isFirstRow = false;

  for (const block of args) {
    cmd = block[0];
    cmdArgs = block.slice(1);

    if (
      cmd === keyNavSelectors.commandNames.steps ||
      cmd === keyNavSelectors.commandNames.relativeQuerySteps
    ) {
      preTarget = current;
    } else if (cmd === keyNavSelectors.commandNames.relativeNthChildDown) {
      currentWidth = current.clientWidth;
      parent = current.closest(`[${KEY_NAV.PARENT}]`);
      parentWidth = parent?.clientWidth;
      columns = Math.floor(parentWidth / currentWidth);
      currentIndex =
        Array.from(current?.closest(`[${KEY_NAV.PARENT}]`)?.children).indexOf(
          current,
        ) + 1;

      target = current.parentNode.querySelector(
        `[keynavitem]:nth-child(${columns + currentIndex})`,
      );
    } else if (cmd === keyNavSelectors.commandNames.relativeNthChildUp) {
      currentWidth = current.clientWidth;
      parent = current.closest(`[${KEY_NAV.PARENT}]`);
      parentWidth = parent?.clientWidth;
      columns = Math.floor(parentWidth / currentWidth);
      currentIndex =
        Array.from(current?.closest(`[${KEY_NAV.PARENT}]`)?.children).indexOf(
          current,
        ) + 1;

      target = current.parentNode.querySelector(
        `[keynavitem]:nth-child(${currentIndex - columns})`,
      );
    } else if (cmd === keyNavSelectors.commandNames.borderLeft) {
      currentWidth = current.clientWidth;
      parent = current.closest(`[${KEY_NAV.PARENT}]`);
      parentWidth = parent?.clientWidth;
      columns = Math.floor(parentWidth / currentWidth);
      currentIndex = Array.from(
        current?.closest(`[${KEY_NAV.PARENT}]`)?.children,
      ).indexOf(current);
      if (currentIndex % columns === 0) {
        isBorder = true;
      } else {
        isBorder = false;
      }
    } else if (cmd === keyNavSelectors.commandNames.borderRight) {
      currentWidth = current.clientWidth;
      parent = current.closest(`[${KEY_NAV.PARENT}]`);
      parentWidth = parent?.clientWidth;
      columns = Math.floor(parentWidth / currentWidth);
      currentIndex =
        Array.from(current?.closest(`[${KEY_NAV.PARENT}]`)?.children).indexOf(
          current,
        ) + 1;
      if (currentIndex > 0 && currentIndex % columns === 0) {
        isBorder = true;
      } else {
        isBorder = false;
      }
    } else if (cmd === keyNavSelectors.commandNames.onlyFirstRow) {
      currentWidth = current.clientWidth;
      parent = current.closest(`[${KEY_NAV.PARENT}]`);
      parentWidth = parent?.clientWidth;
      columns = Math.floor(parentWidth / currentWidth);
      currentIndex = Array.from(
        current?.closest(`[${KEY_NAV.PARENT}]`)?.children,
      ).indexOf(current);
      if (currentIndex < columns) {
        isFirstRow = true;
      } else {
        isFirstRow = false;
      }
    }

    for (const arg of cmdArgs) {
      if (cmd === keyNavSelectors.commandNames.querySelector) {
        target = document.querySelector(arg);
        if (target) {
          break;
        }
      } else if (cmd === keyNavSelectors.commandNames.onlyIndex0) {
        if (
          Array.from(current?.closest(`[${KEY_NAV.PARENT}]`)?.children).indexOf(
            current,
          ) === 0
        ) {
          target = document.querySelector(arg);
          if (target) {
            break;
          }
        }
      } else if (cmd === keyNavSelectors.commandNames.steps) {
        if (arg === KEY_NAV.ITEM_LAST_FOCUS) {
          tmp = preTarget?.querySelector(`[${KEY_NAV.ITEM_LAST_FOCUS}]`);
          if (!tmp) {
            tmp = preTarget?.querySelector(`[${KEY_NAV.ITEM}]`);
          }
          preTarget = tmp;
        } else if (arg === KEY_NAV.ITEM) {
          preTarget = preTarget?.querySelector(`[${KEY_NAV.ITEM}]`);
        } else if (preTarget) {
          preTarget = preTarget[arg];
        }
      } else if (cmd === keyNavSelectors.commandNames.relativeQuerySteps) {
        if (preTarget[arg]) {
          preTarget = preTarget[arg];
        } else {
          preTarget = preTarget.querySelector(arg);
        }
      } else if (cmd === keyNavSelectors.commandNames.borderLeft) {
        if (isBorder && arg) {
          target = document.querySelector(arg);
          if (target) {
            break;
          }
        } else {
          target = current.previousSibling;
        }
      } else if (cmd === keyNavSelectors.commandNames.borderRight) {
        if (isBorder && arg) {
          target = document.querySelector(arg);
          if (target) {
            break;
          }
        } else {
          target = current.nextSibling;
        }
      } else if (cmd === keyNavSelectors.commandNames.onlyFirstRow) {
        if (isFirstRow && arg) {
          target = document.querySelector(arg);
          if (target) {
            break;
          }
        }
      }
    }

    if (
      (cmd === keyNavSelectors.commandNames.steps ||
        cmd === keyNavSelectors.commandNames.relativeQuerySteps) &&
      preTarget
    ) {
      target = preTarget;
    }

    if (target) {
      break;
    }
  }

  return target;
}

export function useKeyNavItem() {
  const $el = useRef(null);

  function start(el) {
    $el.current = el || document.body;
    platform.registerRemoteKeysStart();
    document.addEventListener('keydown', update);
  }

  function end() {
    $el.current = null;
    document.removeEventListener('keydown', update);
  }

  /**
   * @param {HTMLElement} el
   */
  function onKeyNavRight(el) {
    let args = el?.getAttribute('keynavright');
    let target = parseArgs(el, args) || el?.nextSibling;

    if (target) {
      focus(target);
    }
  }

  /**
   * @param {HTMLElement} el
   */
  function onKeyNavLeft(el) {
    let args = el?.getAttribute('keynavleft');
    let target = parseArgs(el, args) || el?.previousSibling;

    if (target) {
      focus(target);
    }
  }

  /**
   * @param {HTMLElement} el
   */
  function onKeyNavDown(el) {
    let args = el?.getAttribute('keynavdown');
    let target = parseArgs(el, args) || el;

    if (target) {
      focus(target);
    }
  }

  /**
   * @param {HTMLElement} el
   */
  function onKeyNavUp(el) {
    let args = el?.getAttribute('keynavup');
    let target = parseArgs(el, args) || el;

    if (target) {
      focus(target);
    }
  }

  function update(e) {
    const keyCode = e.keyCode;
    const key = e.key;
    let automaticCurrent = false;

    if ($el.current === null) {
      return;
    }

    let current = $el.current.querySelector(`[${KEY_NAV.ITEM_FOCUS}]`);

    if (!current) {
      current = $el.current.querySelector(`[${KEY_NAV.ITEM}]`);
      automaticCurrent = true;
    }

    switch (keyCode) {
      case BASE_KEYCODES.up:
        if (platform.isTizen && key !== 'ArrowUp') {
          return false;
        }
        onKeyNavUp(current);
        if (typeof e?.preventDefault === 'function') {
          e.preventDefault();
        }
        break;
      case BASE_KEYCODES.right:
        if (platform.isTizen && key !== 'ArrowRight') {
          return false;
        }
        onKeyNavRight(current);
        if (typeof e?.preventDefault === 'function') {
          e.preventDefault();
        }
        break;
      case BASE_KEYCODES.down:
        if (platform.isTizen && key !== 'ArrowDown') {
          return false;
        }
        onKeyNavDown(current);
        if (typeof e?.preventDefault === 'function') {
          e.preventDefault();
        }
        break;
      case BASE_KEYCODES.left:
        if (platform.isTizen && key !== 'ArrowLeft') {
          return false;
        }
        if (current.parentNode.id !== 'menu-main-items') {
          onKeyNavLeft(current);
          if (typeof e?.preventDefault === 'function') {
            e.preventDefault();
          }
        }
        break;
      case BASE_KEYCODES.enter:
        if (
          automaticCurrent === false &&
          typeof current?.firstChild?.click === 'function'
        ) {
          current.firstChild.click();
        }
        break;
      case BASE_KEYCODES.back:
      case BASE_KEYCODES.back_space:
        if (current.getAttribute('keynavtype') !== 'input') {
          if (typeof e?.preventDefault === 'function') {
            e.preventDefault();
          }
          document.body.dispatchEvent(
            new Event(KEY_NAV.EVENT_BACK, {
              bubbles: true,
              cancelable: false,
            }),
          );
        }
        break;
      case BASE_KEYCODES.play:
        document.body.dispatchEvent(
          new Event(KEY_NAV.EVENT_PLAY, {
            bubbles: true,
            cancelable: false,
          }),
        );
        break;
      case BASE_KEYCODES.pause:
        document.body.dispatchEvent(
          new Event(KEY_NAV.EVENT_PAUSE, {
            bubbles: true,
            cancelable: false,
          }),
        );
        break;
      case BASE_KEYCODES.play_pause:
        document.body.dispatchEvent(
          new Event(KEY_NAV.EVENT_PLAY_PAUSE, {
            bubbles: true,
            cancelable: false,
          }),
        );
        break;
      case BASE_KEYCODES.stop:
        document.body.dispatchEvent(
          new Event(KEY_NAV.EVENT_STOP, {
            bubbles: true,
            cancelable: false,
          }),
        );
        break;
      case BASE_KEYCODES.fast_fwd:
        document.body.dispatchEvent(
          new Event(KEY_NAV.EVENT_FWD, {
            bubbles: true,
            cancelable: false,
          }),
        );
        break;
      case BASE_KEYCODES.rewind:
        document.body.dispatchEvent(
          new Event(KEY_NAV.EVENT_REWIND, {
            bubbles: true,
            cancelable: false,
          }),
        );
        break;
      default:
        break;
    }
  }

  return {
    start,
    end,
    update,
  };
}

/**
 * @param {object} props
 * @param {string} props.keynavdown
 * @param {string} props.keynavup
 * @param {string} props.keynavright
 * @param {string} props.keynavleft
 * @param {string|null} props.keynavdefault
 * @param {string|null} props.keynavitemlastfocus
 */
const NavItem = forwardRef((props, ref) => {
  const mainRef = useRef(null);
  const {children, ...attributes} = props;

  useImperativeHandle(ref, () => ({
    focus() {
      focus(mainRef.current, {mouse: true});
    },
  }));

  function onMouseEnterHandler() {
    if (mainRef.current) {
      focus(mainRef.current, {mouse: true});
    }
  }

  function onKeyFocusHandler(e) {
    if (typeof props?.onMouseEnter === 'function') {
      props.onMouseEnter(e);
    }
  }

  function onKeyBlurHandler(e) {
    if (typeof props?.onMouseLeave === 'function') {
      props.onMouseLeave(e);
    }
  }

  useEffect(() => {
    if (mainRef.current) {
      mainRef.current.addEventListener(KEY_NAV.EVENT_FOCUS, onKeyFocusHandler);
      mainRef.current.addEventListener(KEY_NAV.EVENT_BLUR, onKeyBlurHandler);
    }
  }, []);

  return (
    <div
      ref={mainRef}
      {...attributes}
      keynavitem={'navItem'}
      onMouseEnter={onMouseEnterHandler}>
      {children}
    </div>
  );
});

export default NavItem;
