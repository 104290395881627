import {imageSize} from '@app/utils/imageSize';
import {useGQL, generateQuery} from './useGQL';

/**
 * @typedef {object} Chapter
 * @property {string} free
 * @property {string} slug
 * @property {string} title
 * @property {string} duration
 * @property {object[]} images
 * @property {string} images.url
 * @property {object[]} media
 * @property {string} media.mediaId
 * @property {object} metadataType
 * @property {string} metadataType.value
 */

/**
 * @typedef {object} getDataPayload
 * @property {string} seasonSlug
 * @property {string?} chaptersSort
 * @property {string?} chapterFirst
 * @property {string?} imageSize
 * @property {string?} imageType
 * @property {string?} chapterAfter
 * @property {string?} metadataType
 */

/**
 * @returns {{
 *   data: Chapter[],
 *   getData: function(args:getDataPayload): Promise<Chapter[]>
 * }}
 */
export default function useSeasonChapters() {
  /**
   * @returns {Chapter[]}
   */
  function parseResponse(data) {
    return {
      chapters: data?.show?.season?.chapters?.node,
      cursor: data?.show?.season?.chapters?.cursor,
    };
  }

  /**
   * @param {getDataPayload} payload
   **/
  function getQuery(payload = {}) {
    return generateQuery(
      `query seasonChapters($showSlug: String!, $seasonSlug: String!, $imageSize: String!, $imageType: ImageTypeEnum!, $chaptersSort: SortEnum!, $chapterFirst: Int!, $metadataType: MetadataTypeEnum!, ${
        payload?.chapterAfter ? '$chapterAfter: String!' : ''
      }){
        show(slug: $showSlug) {
          season(slug: $seasonSlug) {
            chapters(first: $chapterFirst, sort: $chaptersSort ${
              payload?.chapterAfter ? 'after: $chapterAfter' : ''
            }) {
              cursor
              node {
                free
                slug
                title
                duration
                images(imageType: $imageType){
                  url(size: $imageSize)
                  progressUrl(size: $imageSize)
                }
                metadata(metadataType: $metadataType) {
                  value
                }
              }
            }
          }
        }
        
      }`,
      {
        chaptersSort: 'SORT_ASC',
        imageSize: imageSize(imageSize.type.show),
        imageType: 'POSTER',
        metadataType: 'VAST_ANDROID',
        ...payload,
      },
    );
  }

  return useGQL(parseResponse, getQuery);
}
