import styles from '@app/styles/components/Showthumb.module.sass';
import {useState} from 'react';
import {useEffect} from 'react';
import {useRef} from 'react';
import NavItem, {keyNavProps} from './KeyNavItem';
import Loading from './Loading';
import platform from '@app/platform';

/**
 * Showthumb.
 *
 * @param {object} props
 * @param {object} props.style
 * @param {string} props.slug
 * @param {string} props.title
 * @param {string} props.image
 * @param {function} props.onFocus
 * @param {function} props.onClick
 * @param {string|string[]} props.keynavup
 * @param {string|string[]} props.keynavdown
 * @param {string|string[]} props.keynavleft
 * @param {string|string[]} props.keynavright
 * @param {string|null} props.keynavdefault
 */
export default function Showthumb(props) {
  const mainRef = useRef(null);
  const [imageUrl, setImageUrl] = useState('images/bg-default-black.png');
  const [loading, setLoading] = useState(false);

  function onScrollHandler() {
    const rect = mainRef.current.getBoundingClientRect();
    const viewportHeight =
      window.innerHeight || document.documentElement.clientHeight;

    if (rect.top < viewportHeight) {
      setTimeout(() => {
        setLoading(true);
        setTimeout(() => {
          setImageUrl(props.image);
        }, 1000);
        document.removeEventListener('scroll', onScrollHandler, true);
      }, 250);
    }
  }

  function onLoadHandler() {
    setLoading(false);
  }

  useEffect(() => {
    if (platform.isWebOS) {
      setImageUrl(props.image);
    } else {
      onScrollHandler();
      document.addEventListener('scroll', onScrollHandler, true);
      return () => {
        document.removeEventListener('scroll', onScrollHandler, true);
      };
    }
  }, []);

  return (
    <NavItem
      {...keyNavProps(props)}
      className={styles.wrap}
      onClick={e => {
        if (typeof props.onClick === 'function') {
          props.onClick(e);
        }
      }}
      onMouseEnter={e => {
        if (typeof props.onFocus === 'function') {
          props.onFocus(e);
        }
      }}>
      <div ref={mainRef} className={styles.main}>
        {imageUrl && (
          <img
            className={styles.picture}
            src={imageUrl}
            alt={props?.title}
            onLoad={onLoadHandler}
          />
        )}
        {loading && <Loading style={{backgroundColor: '#000', zIndex: 2}} />}
      </div>
    </NavItem>
  );
}
