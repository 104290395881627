import useScrollDrag from '@app/hooks/useScrollDrag';
import styles from '@app/styles/components/EpisodesByCategory.module.sass';
import Episodethumb from '@components/Episodethumb';
import {useRef} from 'react';
import {keyNavAttribute, KEY_NAV} from './KeyNavItem';
import {getUserToken} from '@app/stores/auth';
import {useSelector} from 'react-redux';

/**
 * EpisodesByCategory.
 *
 * @param {object} props
 * @param {string} props.label
 * @param {array} props.data
 * @param {function} props.onFocusItem
 * @param {string|string[]} props.keynavup
 * @param {string|string[]} props.keynavdown
 * @param {string|string[]} props.keynavleft
 * @param {string|string[]} props.keynavright
 * @param {boolean} showInfoInBackground - Show info in background
 */
export default function EpisodesByCategory(props) {
  const slider = useRef(null);
  const token = useSelector(getUserToken);
  useScrollDrag(slider);

  return (
    <>
      {props?.data?.length > 0 && (
        <div className={styles.main}>
          <p className={styles.label}>{props.label}</p>
          <div
            className={styles.items}
            ref={slider}
            {...keyNavAttribute(KEY_NAV.PARENT)}>
            {props?.data?.map((item, index) => {
              return (
                <Episodethumb
                  key={item?.slug}
                  image={
                    token && item?.images[0]?.progressUrl
                      ? item?.images[0]?.progressUrl + token
                      : item?.images[0]?.url
                  }
                  season={item?.season}
                  show={item?.show}
                  slug={item?.slug}
                  title={item?.title}
                  free={item?.free}
                  showInfoInBackground={props.showInfoInBackground}
                  keynavup={props.keynavup}
                  keynavdown={props.keynavdown}
                  keynavleft={props.keynavleft}
                  keynavright={props.keynavright}
                  onFocus={() => {
                    if (typeof props.onFocusItem === 'function') {
                      props.onFocusItem(item, index);
                    }
                  }}
                  onClick={() => {
                    if (typeof props.onClickItem === 'function') {
                      props.onClickItem(item, index);
                    }
                  }}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}
