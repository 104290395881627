import styles from '@app/styles/components/Alert.module.sass';
import {useRef} from 'react';
import {useEffect} from 'react';
import {focus, KEY_NAV} from './KeyNavItem';

export default function Alert(props) {
  const ref = useRef();
  const isLayoutRendered = useRef();

  useEffect(() => {
    if (!isLayoutRendered.current) {
      let current = ref.current?.querySelector(`[${KEY_NAV.ITEM}]`);

      if (current) {
        isLayoutRendered.current = true;
        focus(current);
      }
    }
  }, []);

  return (
    <div ref={ref} className={styles.main}>
      <div className={styles.content}>
        <div className={styles.header}>{props.title}</div>
        <div className={styles.body}>{props.body}</div>
        <div className={styles.footer}>{props.footer}</div>
      </div>
    </div>
  );
}
