import styles from '@app/styles/components/Button.module.sass';
import NavItem, {keyNavProps} from './KeyNavItem';

/**
 * @param {object} props
 * @param {string?} props.theme
 * @param {function?} props.onClick
 * @param {string?} props.label
 * @param {JSXElement?} props.icon
 */
export default function Button(props) {
  return (
    <NavItem
      {...keyNavProps(props)}
      className={`${styles.main} ${styles[props.theme] ?? ''} ${
        props.className
      }`}
      onClick={props.onClick}>
      <div className={styles.inner}>
        {props.icon && <span className={styles.icon}>{props.icon}</span>}
        <span className={styles.label}>{props.label}</span>
      </div>
    </NavItem>
  );
}
