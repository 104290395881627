import styles from '@app/styles/components/PopUp.module.sass';
import {getIsOpenPopup, getPopupMessage} from '@app/stores/popup';
import {useSelector} from 'react-redux';
export function PopUp() {
  const message = useSelector(getPopupMessage);
  const userStatus = useSelector(getIsOpenPopup);
  return (
    <div className={`${styles.PopUp} ${userStatus ? styles.isActive : ''}`}>
      {message}
    </div>
  );
}
