/**
 * @param {object} props
 * @param {string} props.fill - color
 * @param {number} props.width
 * @param {number} props.height
 */
export default function IconControlPrev10(props) {
  const fill = props.fill || '#fff';
  const width = props.width || 64;
  const height = props.height || 64;

  return (
    <svg width={width} height={height} viewBox="0 0 64 64" fill="none">
      <path
        d="M31.9733 13.3332V2.6665L18.64 15.9998L31.9733 29.3332V18.6665C40.8 18.6665 47.9734 25.8398 47.9734 34.6665C47.9734 43.4932 40.8 50.6665 31.9733 50.6665C23.1467 50.6665 15.9733 43.4932 15.9733 34.6665H10.64C10.64 46.4532 20.1867 55.9998 31.9733 55.9998C43.76 55.9998 53.3067 46.4532 53.3067 34.6665C53.3067 22.8798 43.76 13.3332 31.9733 13.3332ZM29.04 42.6665H26.7733V33.9732L24.08 34.7998V32.9598L28.8 31.2798H29.04V42.6665ZM40.4534 37.9732C40.4534 38.8265 40.3733 39.5732 40.1867 40.1598C40 40.7465 39.7333 41.2798 39.4133 41.6798C39.0933 42.0798 38.6667 42.3732 38.2133 42.5598C37.76 42.7465 37.2267 42.8265 36.64 42.8265C36.0533 42.8265 35.5467 42.7465 35.0667 42.5598C34.5867 42.3732 34.1867 42.0798 33.84 41.6798C33.4933 41.2798 33.2267 40.7732 33.04 40.1598C32.8533 39.5465 32.7467 38.8265 32.7467 37.9732V35.9998C32.7467 35.1465 32.8267 34.3998 33.0133 33.8132C33.2 33.2265 33.4667 32.6932 33.7867 32.2932C34.1067 31.8932 34.5333 31.5998 34.9867 31.4132C35.44 31.2265 35.9733 31.1465 36.56 31.1465C37.1467 31.1465 37.6534 31.2265 38.1334 31.4132C38.6133 31.5998 39.0133 31.8932 39.36 32.2932C39.7067 32.6932 39.9734 33.1998 40.16 33.8132C40.3467 34.4265 40.4534 35.1465 40.4534 35.9998V37.9732ZM38.1867 35.6798C38.1867 35.1732 38.16 34.7465 38.08 34.3998C38 34.0532 37.8933 33.7865 37.76 33.5732C37.6267 33.3598 37.4667 33.1998 37.2533 33.1198C37.04 33.0398 36.8267 32.9865 36.5867 32.9865C36.3467 32.9865 36.1067 33.0398 35.92 33.1198C35.7334 33.1998 35.5467 33.3598 35.4133 33.5732C35.28 33.7865 35.1733 34.0532 35.0933 34.3998C35.0133 34.7465 34.9867 35.1732 34.9867 35.6798V38.2665C34.9867 38.7732 35.0133 39.1998 35.0933 39.5465C35.1733 39.8932 35.28 40.1865 35.4133 40.3998C35.5467 40.6132 35.7067 40.7732 35.92 40.8532C36.1334 40.9332 36.3467 40.9865 36.5867 40.9865C36.8267 40.9865 37.0667 40.9332 37.2533 40.8532C37.44 40.7732 37.6267 40.6132 37.76 40.3998C37.8933 40.1865 38 39.8932 38.0534 39.5465C38.1067 39.1998 38.16 38.7732 38.16 38.2665V35.6798H38.1867Z"
        fill={fill}
      />
    </svg>
  );
}
