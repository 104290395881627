import {useEffect, useRef} from 'react';

export default function useScrollDrag(slider) {
  const isRendered = useRef(false);
  let isDown = useRef(false);
  let startX = useRef(null);
  let scrollLeft = useRef(null);

  useEffect(() => {
    if (slider?.current) {
      if (!isRendered.current) {
        isRendered.current = true;
        slider.current.addEventListener('mousedown', e => {
          isDown.current = true;
          slider.current.classList.add('active');
          startX.current = e.pageX - slider.current.offsetLeft;
          scrollLeft.current = slider.current.scrollLeft;
        });
        slider.current.addEventListener('mouseleave', () => {
          isDown.current = false;
          slider.current.classList.remove('active');
        });
        slider.current.addEventListener('mouseup', () => {
          isDown.current = false;
          slider.current.classList.remove('active');
        });
        slider.current.addEventListener('mousemove', e => {
          if (!isDown.current) return;
          e.preventDefault();
          const x = e.pageX - slider.current.offsetLeft;
          const walk = (x - startX.current) * 2; //scroll-fast
          slider.current.scrollLeft = scrollLeft.current - walk;
        });
      }
    }
  }, [slider]);
}
