// constants
export const ADD_TOKEN = 'ADD_TOKEN';
export const PREMIUM = 'PREMIUM';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

/**
 * @typedef {{
    access_token: string,
    expires_in: number,
    refresh_token: string,
    scope: string,
    email: string,
    premium: string,
    id: string,
   }} AccessTokenObject
*/

/** @type {{
 * token: AccessTokenObject
 * }} */
const initialState = {};

// reducers
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TOKEN:
      return {
        ...state,
        token: {...action.payload},
      };
    case PREMIUM:
      return {
        ...state,
        token: {
          ...state.token,
          ...action.payload,
        },
      };
    case REMOVE_TOKEN:
      return {
        ...state,
        token: null,
      };
    default:
      return state;
  }
}

// actions
/**
 * @param {AccessTokenObject} payload
 */
export function addUserToken(payload) {
  return async dispatch => {
    return dispatch({
      type: ADD_TOKEN,
      payload: payload,
    });
  };
}

export function logoutUser() {
  return async dispatch => {
    return dispatch({
      type: REMOVE_TOKEN,
      payload: null,
    });
  };
}

export function userPremium(payload) {
  return async dispatch => {
    return dispatch({
      type: PREMIUM,
      payload: payload,
    });
  };
}

// selectors
export function getUserToken(state) {
  return state?.auth?.token?.access_token;
}

export function getUserEmail(state) {
  return state?.auth?.token?.email;
}

export function getUserPremium(state) {
  return state?.auth?.token?.premium;
}

export function getUserId(state) {
  return state?.auth?.token?.id;
}

export function getTokenExpire(state) {
  return state?.auth?.token?.expires_in;
}

export function getUserTokenData(state) {
  return state?.auth?.token;
}
