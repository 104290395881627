export function IconAd(props) {
  const fill = props.fill || '#51A3B9';
  const width = props.width || 44;
  const height = props.height || 38;

  return (
    <svg width={width} height={height} viewBox="0 0 44 38" fill="none">
      <path
        d="M27.251 11.3502L30.833 26.3502C30.8885 26.5766 30.8577 26.8155 30.7468 27.0204C30.6359 27.2254 30.4528 27.3818 30.233 27.4592C30.0881 27.5102 29.9328 27.5243 29.781 27.5002L12.525 24.8272L15.048 33.7722C15.2131 34.3383 15.1777 34.9441 14.948 35.4872C14.8368 35.8111 14.6471 36.1024 14.396 36.3352C14.1457 36.567 13.8415 36.7327 13.511 36.8172C12.9927 36.9545 12.4431 36.9102 11.9534 36.6919C11.4637 36.4737 11.0634 36.0944 10.819 35.6172C10.6824 35.4022 10.578 35.1684 10.509 34.9232L7.998 26.1012C7.998 26.1012 4.20801 28.6662 1.41701 23.2482C1.41701 23.2482 -0.656993 17.9482 4.41701 15.3882L11.528 12.8882L23.32 1.26622C23.4376 1.15101 23.5834 1.06897 23.7429 1.02837C23.9024 0.987766 24.0698 0.990035 24.2281 1.03502C24.3864 1.08001 24.5301 1.16607 24.6444 1.28447C24.7587 1.40288 24.8396 1.54942 24.879 1.70922L26.513 8.28722C27.8395 8.0938 29.19 8.40887 30.294 9.16924C31.402 9.93286 32.1836 11.0838 32.485 12.3952C33.643 16.9102 29.606 19.3832 29.208 19.5382"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.893 11.7751L42.823 9.11816"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.893 16.9722L39.931 18.0272"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.25 7.61334L36.532 3.27734"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
