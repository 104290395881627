import {ISGLOBAL} from './constants';

const platform = {};

const userAgent = (navigator.userAgent || '').toLowerCase();

platform.webOS = window.webOS;

platform.isWebOS = platform?.webOS?.platform?.tv;

platform.isVida = false;

platform.isWhaleOS =
  userAgent.indexOf('whaletv') > 0 ||
  userAgent.indexOf('philips') > 0 ||
  userAgent.indexOf('nettv') > 0;

platform.isTizen = process?.env?.REACT_APP_TIZEN === 'true';

try {
  if (typeof Hisense_GetDeviceID === 'function') {
    platform.isVida = true;
  }
} catch (e) {
  console.log(e);
}

if (platform.isTizen) {
  platform.bundleId = 'pe.tvgo.smarttv.tizen';
} else if (platform.isVida) {
  platform.bundleId = 'pe.tvgo.smarttv.hisense';
} else if (platform.isWhaleOS) {
  platform.bundleId = 'pe.tvgo.smarttv.zeans';
} else if (platform.isWebOS) {
  platform.bundleId = 'pe.tvgo.smarttv.webos';
} else {
  platform.bundleId = 'pe.tvgo.smarttv.generic';
}

if (ISGLOBAL) {
  platform.bundleId = platform.bundleId + '.int';
}

platform.version = process?.env?.REACT_APP_VERSION || '';

platform.OSVersion = null;

if (platform.isTizen) {
  try {
    platform.OSVersion = window?.webapis?.productinfo?.getVersion();
    platform.firmwareVersion = window?.webapis?.productinfo?.getFirmware();
    platform.UID = window?.webapis?.productinfo?.getDuid();
    platform.modelCode = window?.webapis?.productinfo?.getModelCode();
    platform.model = window?.webapis?.productinfo?.getModel();
    platform.realModel = window?.webapis?.productinfo?.getRealModel();
  } catch (e) {
    console.log(e);
  }
} else if (platform.isWebOS) {
  try {
    window?.webOS?.deviceInfo(function (device) {
      platform.OSVersion = device?.version;
      platform.sdkVersion = device?.sdkVersion;
      platform.model = device?.modelName;
      platform.brandName = device?.brandName;
    });
  } catch (e) {
    console.log(e);
  }
}

platform.exit = function () {
  if (
    platform.isWebOS === true &&
    typeof platform?.webOS?.platformBack === 'function'
  ) {
    platform.webOS.platformBack();
  } else if (
    platform.isTizen &&
    window.tizen &&
    window.tizen.application &&
    typeof window.tizen.application.getCurrentApplication === 'function'
  ) {
    window.tizen.application.getCurrentApplication().exit();
  } else {
    window.close();
  }
};

platform.registerRemoteKeysStart = function () {
  if (
    platform.isTizen &&
    window.tizen &&
    window.tizen.tvinputdevice &&
    typeof window.tizen.tvinputdevice.registerKey === 'function'
  ) {
    window.tizen.tvinputdevice.registerKey('MediaPlayPause');
    window.tizen.tvinputdevice.registerKey('MediaFastForward');
    window.tizen.tvinputdevice.registerKey('MediaPause');
    window.tizen.tvinputdevice.registerKey('MediaPlay');
    window.tizen.tvinputdevice.registerKey('MediaStop');
    window.tizen.tvinputdevice.registerKey('MediaRewind');
  }
};

const KEYCODES = {
  up: 38,
  right: 39,
  down: 40,
  left: 37,
  enter: 13,
  back_space: 8,
  back: 8,
  blue: 406,
  red: 403,
  green: 404,
  yellow: 405,
  k_0: 48,
  k_1: 49,
  k_2: 50,
  k_3: 51,
  k_4: 52,
  k_5: 53,
  k_6: 54,
  k_7: 55,
  k_8: 56,
  k_9: 57,
  play: 415,
  pause: 19,
  play_pause: 463,
  stop: 413,
  fast_fwd: 417,
  rewind: 412,
};

platform.KEYCODES = KEYCODES;

if (platform.isWhaleOS) {
  platform.KEYCODES = {
    up: window?.VK_UP,
    right: window?.VK_RIGHT,
    down: window?.VK_DOWN,
    left: window?.VK_LEFT,
    enter: window?.VK_ENTER,
    back_space: window?.VK_BACK_SPACE,
    back: window?.VK_BACK,
    blue: window?.VK_BLUE,
    red: window?.VK_RED,
    green: window?.VK_GREEN,
    yellow: window?.VK_YELLOW,
    k_0: window?.VK_0,
    k_1: window?.VK_1,
    k_2: window?.VK_2,
    k_3: window?.VK_3,
    k_4: window?.VK_4,
    k_5: window?.VK_5,
    k_6: window?.VK_6,
    k_7: window?.VK_7,
    k_8: window?.VK_8,
    k_9: window?.VK_9,
    play: window?.VK_PLAY,
    pause: window?.VK_PAUSE,
    play_pause: window?.VK_PLAY_PAUSE,
    stop: window?.VK_STOP,
    fast_fwd: window?.VK_FAST_FWD,
    rewind: window?.VK_REWIND,
  };
}

if (platform.isWebOS) {
  platform.KEYCODES = {
    ...KEYCODES,
    play_pause: 10252,
    back_space: 461,
    back: 461,
    exit: 10182,
  };
}

if (platform.isTizen) {
  platform.KEYCODES = {
    ...KEYCODES,
    back: 10009,
    play_pause: 10252,
    done: 65376,
    cancel: 65385,
  };
}

export default platform;
