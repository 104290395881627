/**
 * @param {object} props
 * @param {string} props.fill - color
 * @param {number} props.width
 * @param {number} props.height
 */
export default function IconCategories(props) {
  const fill = props.fill || '#fff';
  const width = props.width || 28;
  const height = props.height || 28;

  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none">
      <g clipPath="url(#clip0_1_59)">
        <path
          d="M7.18135 16.7231C7.18135 17.2027 6.79254 17.5915 6.31296 17.5915H0.868387C0.38881 17.5915 0 17.2027 0 16.7231V11.2785C0 10.799 0.38881 10.4102 0.868387 10.4102H6.31296C6.7926 10.4102 7.18135 10.799 7.18135 11.2785V16.7231Z"
          fill={fill}
        />
        <path
          d="M28 16.7222C28 17.2018 27.6112 17.5906 27.1316 17.5906H21.687C21.2074 17.5906 20.8186 17.2018 20.8186 16.7222V11.2776C20.8186 10.798 21.2074 10.4092 21.687 10.4092H27.1316C27.6112 10.4092 28 10.798 28 11.2776V16.7222Z"
          fill={fill}
        />
        <path
          d="M16.7225 0.142578H11.2774C10.7979 0.142578 10.4092 0.531326 10.4092 1.01127V6.45542C10.4092 6.93524 10.7979 7.32399 11.2774 7.32399H16.7225C17.2019 7.32399 17.5907 6.93524 17.5907 6.45542V1.01127C17.5907 0.531326 17.2019 0.142578 16.7225 0.142578Z"
          fill={fill}
        />
        <path
          d="M6.31278 0.142578H0.868697C0.388748 0.142578 0 0.531326 0 1.01127V6.45542C0 6.93524 0.388748 7.32399 0.868697 7.32399H6.31284C6.79266 7.32399 7.18141 6.93524 7.18141 6.45542V1.01127C7.18135 0.531326 6.7926 0.142578 6.31278 0.142578Z"
          fill={fill}
        />
        <path
          d="M27.1313 0.142578H21.6872C21.2074 0.142578 20.8186 0.531326 20.8186 1.01127V6.45542C20.8186 6.93524 21.2074 7.32399 21.6872 7.32399H27.1313C27.6112 7.32399 28 6.93524 28 6.45542V1.01127C28 0.531326 27.6112 0.142578 27.1313 0.142578Z"
          fill={fill}
        />
        <path
          d="M16.7225 10.4092H11.2774C10.7979 10.4092 10.4092 10.7979 10.4092 11.2779V16.722C10.4092 17.2018 10.7979 17.5906 11.2774 17.5906H16.7225C17.2019 17.5906 17.5907 17.2018 17.5907 16.722V11.2779C17.5907 10.7979 17.2019 10.4092 16.7225 10.4092Z"
          fill={fill}
        />
        <path
          d="M16.7225 20.6763H11.2774C10.7979 20.6763 10.4092 21.065 10.4092 21.545V26.9891C10.4092 27.4689 10.7979 27.8577 11.2774 27.8577H16.7225C17.2019 27.8577 17.5907 27.4689 17.5907 26.9891V21.545C17.5907 21.065 17.2019 20.6763 16.7225 20.6763Z"
          fill={fill}
        />
        <path
          d="M7.18135 26.9897C7.18135 27.4693 6.79254 27.8581 6.31296 27.8581H0.868387C0.38881 27.8581 0 27.4693 0 26.9897V21.5451C0 21.0656 0.38881 20.6768 0.868387 20.6768H6.31296C6.7926 20.6768 7.18135 21.0656 7.18135 21.5451V26.9897Z"
          fill={fill}
        />
        <path
          d="M28 26.9888C28 27.4684 27.6112 27.8572 27.1316 27.8572H21.687C21.2074 27.8572 20.8186 27.4684 20.8186 26.9888V21.5442C20.8186 21.0646 21.2074 20.6758 21.687 20.6758H27.1316C27.6112 20.6758 28 21.0646 28 21.5442V26.9888Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1_59">
          <rect width="28" height="28" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
}
