import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import ProxyPolyfillBuilder from 'proxy-polyfill/src/proxy';

if (typeof window.Proxy !== 'function') {
  window.Proxy = ProxyPolyfillBuilder;
}

if (typeof Object.values !== 'function') {
  Object.values = function (payload) {
    const result = [];
    let item;
    for (item in payload) {
      result.push(payload[item]);
    }
    return result;
  };
}

if (typeof Object.keys !== 'function') {
  Object.keys = function (obj) {
    if (typeof obj !== 'object' || obj === null) {
      throw new TypeError('Object.keys solo acepta objetos no nulos');
    }

    var keys = [];
    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        keys.push(key);
      }
    }
    return keys;
  };
}

if (!Array.prototype.every) {
  Array.prototype.every = function (callback, thisArg) {
    if (this == null) {
      throw new TypeError('this no está definido');
    }

    if (typeof callback !== 'function') {
      throw new TypeError(callback + ' no es una función');
    }

    var array = Object(this);
    var length = array.length >>> 0; // Nos aseguramos de que length sea un número entero no negativo.

    for (var i = 0; i < length; i++) {
      if (i in array && !callback.call(thisArg, array[i], i, array)) {
        return false;
      }
    }

    return true;
  };
}
