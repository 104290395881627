import {ACTIONS} from '@app/constants';
import {trackEvent} from '@app/libs/metrics';
import platform from '@app/platform';
import {addCurrentTime} from '@app/stores/player';
import {STATUS_TYPE, getPopupMessage} from '@app/stores/popup';
import styles from '@app/styles/components/VideoPlayer.module.sass';
import {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import ButtonArrow from './ButtonArrow';
import NavItem, {
  KEY_NAV,
  focus,
  keyNavAttribute,
  keyNavSelectors,
} from './KeyNavItem';
import Loading from './Loading';
import {IconClose} from './icons/IconClose';
import IconControlNext10 from './icons/IconControlNext10';
import IconControlPause from './icons/IconControlPause';
import IconControlPrev10 from './icons/IconControlPrev10';
import IconPlay from './icons/IconPlay';
if (platform.isTizen && !window?.MediastreamPlayer) {
  window.MediastreamPlayer =
    require('@app/libs/TizenMediastreamPlayer').default;
}

const TIMEOUT_CONTROL_TIME = 3000;

function formatSeconds(_seconds) {
  const seconds = Math.floor(_seconds);
  if (!seconds) {
    return '00:00';
  }

  let h = Math.floor(seconds / 60 / 60);
  let m = Math.floor(seconds / 60) - h * 60;
  let s = Math.floor(seconds - m * 60 - h * 3600);

  if (h < 10) {
    h = h < 1 ? '' : `0${h}`;
  }
  if (m < 10) {
    m = `0${m}`;
  }
  if (s < 10) {
    s = `0${s}`;
  }

  return (h ? h + ':' : '') + m + ':' + s;
}

/**
 * MediaPlayer
 * @param {Object} props
 * @param {string} props.mediaId
 * @param {string} props.mediaToken
 * @param {string} props.title
 * @param {number} props.duration
 * @param {string} props.vastUrl
 * @param {boolean} props.isLive
 * @param {function} props.onNextChapter
 * @param {boolean} props.nextChapter
 */
export default function MediaPlayer(props) {
  const navigate = useNavigate();
  const isRenderd = useRef(false);
  const isVideoEnded = useRef(false);
  const player = useRef(null);
  const showControlsTimeout = useRef(0);
  const [showControls, setShowControls] = useState(true);
  const $player = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentPercent, setCurrentPercent] = useState(0);
  const [isPlay, setIsPlay] = useState(false);
  const [isVideoEnd, setIsVideoEnd] = useState(false);
  const [isBuffering, setIsBuffering] = useState(false);
  const playerOptions = useRef({});
  const ref = useRef();
  const isLayoutRendered = useRef();
  const body = useRef(document.body);
  const seek_next_interval = useRef(10);
  const seek_prev_interval = useRef(10);
  const seek_timeout = useRef(1);
  const isFirstRender = useRef(true);
  const dispatch = useDispatch();
  const setLiveContent = useRef(false);
  const player_initial_timeout = useRef(false);
  const popMessage = useSelector(getPopupMessage);

  function onClickTimelineHandler(e) {
    const total_width = e?.target.clientWidth;
    const x = e?.nativeEvent?.layerX;
    const p = (x * 100) / total_width;
    const duration = props.duration;
    const new_current_time = (p * duration) / 100;
    player.current.seekTo(new_current_time);
  }

  function onPlayHandler() {
    if (typeof player.current?.videoPlay === 'function') {
      player.current.videoPlay();
    }
  }

  function onPauseHandler() {
    if (typeof player.current?.videoStop === 'function') {
      player.current.videoStop();
    }
  }

  function onNextChapterHandler() {
    if (typeof props?.onNextChapter === 'function') {
      props.onNextChapter();
    }
  }

  const seekToNext = useCallback(() => {
    if (typeof player.current?.seekTo === 'function') {
      clearTimeout(seek_timeout.current);
      seek_timeout.current = setTimeout(() => {
        seek_next_interval.current = 10;
      }, 500);
      player.current.seekTo(currentTime + seek_next_interval.current);
      seek_next_interval.current += 10;
      if (seek_next_interval.current > 60) {
        seek_next_interval.current = 60;
      }
    }
  }, [currentTime]);

  const seekToPrev = useCallback(() => {
    if (typeof player.current?.seekTo === 'function') {
      clearTimeout(seek_timeout.current);
      seek_timeout.current = setTimeout(() => {
        seek_prev_interval.current = 10;
      }, 500);
      player.current.seekTo(currentTime - seek_prev_interval.current);
      seek_prev_interval.current += 10;
      if (seek_prev_interval.current > 60) {
        seek_prev_interval.current = 60;
      }
    }
  }, [currentTime]);

  const onFWDHandler = useCallback(() => {
    seekToNext();
  }, [currentTime]);

  const onRewindHandler = useCallback(() => {
    seekToPrev();
  }, [currentTime]);

  const onPlayPauseHandler = useCallback(() => {
    if (typeof player.current?.videoPlay === 'function') {
      if (isPlay) {
        player.current.videoStop();
      } else {
        player.current.videoPlay();
      }
    }
  }, [isPlay]);

  const onStopHandler = useCallback(() => {
    if (typeof player.current?.videoStop === 'function') {
      player.current.videoStop();
      if (platform.isTizen) {
        player.current.close();
      }
      navigate(-1);
    }
  }, []);

  const onBackHandler = useCallback(() => {
    navigate(-1);
  }, []);

  const onSeekNext = useCallback(() => {
    seekToNext();
  }, [currentTime]);

  const onSeekPrev = useCallback(() => {
    seekToPrev();
  }, [currentTime]);

  function onKeyDownHandler() {
    setShowControls(true);
    clearTimeout(showControlsTimeout.current);
    showControlsTimeout.current = setTimeout(() => {
      setShowControls(false);
    }, TIMEOUT_CONTROL_TIME);
  }

  function onVisibilityChange() {
    if (document.hidden) {
      if (platform.isTizen) {
        onPauseHandler();
      }
    } else {
      if (platform.isTizen) {
        onPlayHandler();
      }
    }
  }

  useEffect(() => {
    if (!isRenderd.current) {
      isRenderd.current = true;
      clearTimeout(showControlsTimeout.current);
      showControlsTimeout.current = setTimeout(() => {
        setShowControls(false);
      }, TIMEOUT_CONTROL_TIME);
    }

    document.addEventListener('keydown', onKeyDownHandler);
    document.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      document.removeEventListener('keydown', onKeyDownHandler);
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, []);

  useEffect(() => {
    body.current.addEventListener(KEY_NAV.EVENT_PLAY, onPlayHandler);
    body.current.addEventListener(KEY_NAV.EVENT_PAUSE, onPauseHandler);
    body.current.addEventListener(KEY_NAV.EVENT_STOP, onStopHandler);
    body.current.addEventListener(KEY_NAV.EVENT_FWD, onFWDHandler);
    body.current.addEventListener(KEY_NAV.EVENT_REWIND, onRewindHandler);
    body.current.addEventListener(KEY_NAV.EVENT_PLAY_PAUSE, onPlayPauseHandler);

    return () => {
      body.current.removeEventListener(KEY_NAV.EVENT_PLAY, onPlayHandler);
      body.current.removeEventListener(KEY_NAV.EVENT_PAUSE, onPauseHandler);
      body.current.removeEventListener(KEY_NAV.EVENT_STOP, onStopHandler);
      body.current.removeEventListener(KEY_NAV.EVENT_FWD, onFWDHandler);
      body.current.removeEventListener(KEY_NAV.EVENT_REWIND, onRewindHandler);
      body.current.removeEventListener(
        KEY_NAV.EVENT_PLAY_PAUSE,
        onPlayPauseHandler,
      );
    };
  }, [currentTime, isPlay]);

  useEffect(() => {
    playerOptions.current = {
      width: window.innerWidth, // Width in pixels
      height: window.innerHeight, // Height in pixels
      type: props.isLive ? 'live' : 'media', // Video type. Possible values: "media", "live"
      id: props.mediaId, // Video ID
      access_token: props.mediaToken, // Access token
      ads: {
        map: props.vastUrl,
      },
      autoplay: true, // Enable autoplay. Possible values: true, false
      controls: false,
      volume: 100,
      events: {
        // Callbacks to be triggered when certain actions are executed by the player. All optional.
        onPlayerReady: function () {
          // Optional callback to be triggered as soon as the player has finished loading
        },
        onPlay: function () {
          // Optional callback to be triggered as soon as the player starts playing
          trackEvent({
            action: ACTIONS.PLAYER.videoStart,
            label: props.title,
          });
          setIsPlay(true);
          setIsBuffering(false);
          setIsVideoEnd(false);
          if (isFirstRender.current) {
            isFirstRender.current = false;
          }
          if (props?.currentTime && !player_initial_timeout.current) {
            player_initial_timeout.current = true;
            player.current.seekTo(props.currentTime);
          }
          if (props.isLive && setLiveContent.current) {
            player.current.seekTo(0);
            setLiveContent.current = false;
          }
        },
        onVideoEnd: function () {
          // Optional callback to be triggered when the video ends playing
          trackEvent({
            action: ACTIONS.PLAYER.videoEnd,
            label: props.title,
          });
          isVideoEnded.current = true;
          setIsPlay(false);
          setIsVideoEnd(true);
        },
        onVideoStop: function () {
          // Optional callback to be triggered when the user stops or pauses the video
          setShowControls(true);
          clearTimeout(showControlsTimeout.current);
          setIsPlay(false);
          if (props.isLive && !setLiveContent.current) {
            setLiveContent.current = true;
          }
        },
        onReplay: function () {},
        onVideoError: function () {
          // Optional callback to be triggered when there's a playback error
          setShowControls(true);
        },
        onTimeUpdate: function (time) {
          // Optional callback to be triggered when time is updated
          let p = 0;
          let currentTime = time;
          const duration = props.duration;

          setCurrentTime(currentTime);

          dispatch(addCurrentTime(currentTime));

          if (!props.isLive) {
            if (props.duration && currentTime) {
              p = (currentTime * 100) / duration;
              setCurrentPercent(p);
            }
          }
        },

        onBuffering: function () {
          setIsBuffering(true);
        },
        onBufferingStart: function () {
          setIsBuffering(true);
        },
      },
    };
    player.current = new window.MediastreamPlayer(
      'mdstrm-player',
      playerOptions.current,
    );
    window._player = player.current;
    return () => {
      setLiveContent.current = false;
    };
  }, [props]);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (!isLayoutRendered.current) {
        let current = ref.current?.querySelector(`#player-control-play`);
        if (current) {
          isLayoutRendered.current = true;
          focus(current);
        }
      } else {
        let current = ref.current?.querySelector(`#player-control-play`);
        if (isFirstRender.current) {
          current.style.pointerEvents = 'none';
        } else {
          current.style.pointerEvents = 'auto';
        }
      }
    }, 500);
  });

  useEffect(() => {
    return () => {
      if (platform.isTizen && typeof player.current?.close === 'function') {
        player.current.close();
      }
    };
  }, [props.current]);

  useEffect(() => {
    if (popMessage === STATUS_TYPE.RECONNECTION_SUCCESS) {
      onPlayHandler();
    } else if (popMessage === STATUS_TYPE.CONNECTION_ERROR) {
      onPauseHandler();
    }
  }, [popMessage]);

  return (
    <div ref={ref} className={styles.wrap}>
      {isBuffering && <Loading style={{zIndex: 100}} />}
      <div
        className={`${styles.overlay} ${
          showControls ? styles.showControls : ''
        }`}>
        <div className={styles.title}>{props.title}</div>
        <div {...keyNavAttribute(KEY_NAV.PARENT)}>
          <NavItem
            id={'player-control-close'}
            keynavdown={keyNavSelectors()
              .querySelector(
                '#player-control-next-chapter',
                `#controls-main [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                `#controls-main [${KEY_NAV.ITEM}]`,
              )
              .build()}>
            <div className={styles.btnBack} onClick={onBackHandler}>
              <IconClose />
            </div>
          </NavItem>
        </div>
        <div
          id="controls-main"
          className={styles.controls}
          {...keyNavAttribute(KEY_NAV.PARENT)}>
          <div className={styles.controls_inner}>
            {!props.isLive && (
              <div className={styles.timeline} onClick={onClickTimelineHandler}>
                <div
                  className={styles.timeline_played}
                  style={{width: `${currentPercent}%`}}
                />
              </div>
            )}
            <div className={styles.buttons}>
              {!props.isLive && (
                <div className={styles.time_played}>
                  {formatSeconds(currentTime)}
                </div>
              )}
              <div className={styles.controls_player}>
                {!props.isLive && (
                  <NavItem
                    id={'player-control-pp'}
                    className={styles.btn_prev}
                    keynavup={keyNavSelectors()
                      .querySelector(
                        `#player-control-next-chapter`,
                        `#player-control-close`,
                      )
                      .build()}
                    keynavright={keyNavSelectors()
                      .steps('nextSibling')
                      .build()}>
                    <div onClick={onSeekPrev}>
                      <IconControlPrev10 height={40} />
                    </div>
                  </NavItem>
                )}
                <NavItem
                  id={'player-control-play'}
                  className={styles.btn_play}
                  keynavup={keyNavSelectors()
                    .querySelector(
                      `#player-control-next-chapter`,
                      `#player-control-close`,
                    )
                    .build()}
                  keynavleft={keyNavSelectors()
                    .steps('previousSibling')
                    .build()}
                  keynavright={keyNavSelectors().steps('nextSibling').build()}>
                  <div
                    onClick={() =>
                      isPlay
                        ? player.current.videoStop()
                        : player.current.videoPlay()
                    }>
                    {!isPlay && <IconPlay width={30} height={30} />}
                    {isPlay && <IconControlPause width={30} height={30} />}
                  </div>
                </NavItem>
                {!props.isLive && (
                  <NavItem
                    id={'player-control-ff'}
                    className={styles.btn_next}
                    keynavup={keyNavSelectors()
                      .querySelector(
                        `#player-control-next-chapter`,
                        `#player-control-close`,
                      )
                      .build()}
                    keynavleft={keyNavSelectors()
                      .steps('previousSibling')
                      .build()}>
                    <div onClick={onSeekNext}>
                      <IconControlNext10 height={40} />
                    </div>
                  </NavItem>
                )}
              </div>
              {!props.isLive && (
                <div className={styles.time_total}>
                  {formatSeconds(props.duration)}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div ref={$player} id="mdstrm-player" className={styles.player} />
      {props.nextChapter && isVideoEnd && (
        <div className={`${styles.nextChapterContent}`}>
          <ButtonArrow
            id={'player-control-next-chapter'}
            label={'Siguiente capitulo'}
            onClick={onNextChapterHandler}
            keynavup={keyNavSelectors()
              .querySelector('#player-control-close')
              .build()}
            keynavdown={keyNavSelectors()
              .querySelector(
                `#controls-main [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                `#controls-main [${KEY_NAV.ITEM}]`,
              )
              .build()}
          />
        </div>
      )}
    </div>
  );
}
