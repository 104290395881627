import appRoutes from '@app/appRoutes';
import {getBanners} from '@app/stores/banners';
import {getCategory} from '@app/stores/shows';
import styles from '@app/styles/views/Category.module.sass';
import {getShowImageSource} from '@app/utils/getShowImageSource';
import BannerMain from '@components/BannerMain';
import {
  focus,
  keyNavAttribute,
  keyNavSelectors,
  KEY_NAV,
} from '@components/KeyNavItem';
import Showthumb from '@components/Showthumb';
import {useLayoutEffect} from 'react';
import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

export default function Category() {
  const navigate = useNavigate();
  const {category_slug} = useParams();
  const isRendered = useRef(false);
  const [banner, setBanner] = useState(null);
  const [showFocus, setShowFocus] = useState(null);
  const category = useSelector(state => getCategory(state, category_slug));
  const bannerShow = useSelector(getBanners);
  const ref = useRef();
  const isLayoutRendered = useRef(false);

  function onFocusItemGrid(data) {
    setShowFocus(data);
  }

  function onClickItemGrid(data) {
    if (data?.slug) {
      navigate(appRoutes.category(`${data.slug}`));
    }
  }

  useEffect(() => {
    let b = null;

    if (showFocus) {
      if (bannerShow) {
        b = bannerShow[showFocus?.slug];
      }

      if (!b) {
        b = getShowImageSource(showFocus?.images);
      }

      setBanner(b);
    }
  }, [showFocus, bannerShow]);

  useEffect(() => {
    if (!isRendered.current) {
      isRendered.current = true;
    }
  }, []);

  useEffect(() => {
    if (isLayoutRendered.current) {
      isLayoutRendered.current = null;
    }
  }, [category_slug]);

  useLayoutEffect(() => {
    if (!isLayoutRendered.current) {
      let current = ref.current?.querySelector(`[${KEY_NAV.ITEM}]`);

      if (current) {
        isLayoutRendered.current = true;
        focus(current);
      }
    }
  });

  return (
    <div id="category" ref={ref} className={styles.main}>
      <div className={styles.banner}>
        <BannerMain source={banner} />
      </div>
      <div {...keyNavAttribute(KEY_NAV.SCROLL_DOWN)} className={styles.body}>
        <div {...keyNavAttribute(KEY_NAV.PARENT)} className={styles.grid}>
          {category?.shows?.map((item, index) => {
            if (index === 0 && !showFocus) {
              onFocusItemGrid(item);
            }
            return (
              <Showthumb
                key={item?.slug}
                slug={item?.slug}
                label={item?.title}
                image={item?.images[0]?.url}
                onFocus={() => onFocusItemGrid(item)}
                onClick={() => onClickItemGrid(item)}
                keynavdown={keyNavSelectors().relativeNthChildDown().build()}
                keynavup={keyNavSelectors().relativeNthChildUp().build()}
                keynavleft={keyNavSelectors()
                  .borderLeft(
                    `#menu [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                    `#menu [${KEY_NAV.ITEM}]`,
                  )
                  .build()}
                keynavright={keyNavSelectors()
                  .borderRight(`[${KEY_NAV.ITEM_FOCUS}]`)
                  .build()}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
