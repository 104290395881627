/**
 * @param {object} props
 * @param {string} props.fill - color
 * @param {number} props.width
 * @param {number} props.height
 */
export default function IconLive(props) {
  const fill = props.fill || '#fff';
  const width = props.width || 44;
  const height = props.height || 28;

  return (
    <svg width={width} height={height} viewBox="0 0 44 28" fill="none">
      <path
        d="M39.235 14.049a.52.52 0 0 0-.815 0 .753.753 0 0 0 0 .945 5.802 5.802 0 0 1-.236 7.292.753.753 0 0 0 0 .946.52.52 0 0 0 .814 0 7.303 7.303 0 0 0 .237-9.183z"
        fill={fill}
      />
      <path
        d="M41.354 11.588a.478.478 0 0 0-.788 0 .856.856 0 0 0 0 1.006 9.228 9.228 0 0 1 1.796 5.938 10.466 10.466 0 0 1-2.133 6.165.856.856 0 0 0 0 1.005.477.477 0 0 0 .788 0 12.103 12.103 0 0 0 2.459-7.123c.12-2.506-.63-4.977-2.122-6.991zM5.07 15a.752.752 0 0 0-.082-1.03.52.52 0 0 0-.732.084 7.302 7.302 0 0 0 .236 9.193.523.523 0 0 0 .815 0 .753.753 0 0 0 0-.946A5.8 5.8 0 0 1 5.071 15z"
        fill={fill}
      />
      <path
        d="M1.123 18.533a9.217 9.217 0 0 1 1.798-5.939.856.856 0 0 0 0-1.005.476.476 0 0 0-.787 0A10.864 10.864 0 0 0 .013 18.58a12.09 12.09 0 0 0 2.458 7.122.479.479 0 0 0 .788 0 .855.855 0 0 0 0-1.005 10.471 10.471 0 0 1-2.136-6.165z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.724 9.65h8.857a1.929 1.929 0 0 1 1.917 1.923v14.504c0 .51-.202.999-.562 1.36-.359.36-.846.562-1.355.563h-19.68c-.508 0-.995-.203-1.354-.564-.36-.36-.562-.85-.563-1.359V11.575c.001-.51.203-.999.563-1.36.36-.36.846-.562 1.355-.563h8.275L16.07 3.785a.632.632 0 1 1 1.033-.728l4.325 6.179L28.734.234a.631.631 0 0 1 1.119.334.635.635 0 0 1-.138.465l-6.99 8.618zm-3.32 4.45 6.413 4.175a.603.603 0 0 1 .273.498.587.587 0 0 1-.273.497l-6.414 4.174a.62.62 0 0 1-.863-.19.59.59 0 0 1-.087-.308v-8.349a.59.59 0 0 1 .323-.527.623.623 0 0 1 .627.03z"
        fill={fill}
      />
    </svg>
  );
}
