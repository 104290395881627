import styles from '@app/styles/components/Loading.module.sass';

export default function Loading(props) {
  const local_styles = {};
  let localClass = [];

  if (props.fixed) {
    local_styles.main = {
      position: 'fixed',
    };
  }
  if (props.relative) {
    local_styles.main = {
      position: 'relative',
    };
  }

  if (props.tiny) {
    localClass.push(styles.tiny);
  } else if (props.verytiny) {
    localClass.push(styles.verytiny);
  }

  if (props.dark) {
    localClass.push(styles.theme_dark);
  }

  return (
    <div
      className={`${styles.main} ${localClass.join(' ')}`}
      style={{...local_styles.main, ...(props.style || {})}}>
      <div className={styles.loader_content} style={{...local_styles.content}}>
        <div className={styles.lds_ring}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
