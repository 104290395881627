/**
 * @param {object} props
 * @param {string} props.fill - color
 * @param {number} props.width
 * @param {number} props.height
 */
export default function IconProfile(props) {
  const fill = props.fill || '#fff';
  const width = props.width || 28;
  const height = props.width || 28;

  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none">
      <path
        d="M14 0C9.92912 0 6.61719 3.31193 6.61719 7.38281C6.61719 11.4537 9.92912 14.7656 14 14.7656C18.0709 14.7656 21.3828 11.4537 21.3828 7.38281C21.3828 3.31193 18.0709 0 14 0Z"
        fill={fill}
      />
      <path
        d="M23.1856 19.5888C21.1644 17.5365 18.4849 16.4062 15.6406 16.4062H12.3594C9.51519 16.4062 6.83561 17.5365 4.81436 19.5888C2.80301 21.631 1.69531 24.3268 1.69531 27.1797C1.69531 27.6327 2.06259 28 2.51562 28H25.4844C25.9374 28 26.3047 27.6327 26.3047 27.1797C26.3047 24.3268 25.197 21.631 23.1856 19.5888Z"
        fill={fill}
      />
    </svg>
  );
}
