import {fetchApi} from '@app/api/repository';
import {useGQL, generateQuery} from './useGQL';
import {imageSize} from '@app/utils/imageSize';
/**
 * @typedef {object} Show
 * @property {string} title
 * @property {string} slug
 * @property {object[]} images
 * @property {string} images.url
 */

/**
 * @typedef {object} getDataPayload
 * @property {string[]} slugs
 * @property {string} imageSize
 * @property {string} imageType
 */

/** @returns {Show[]} */
function parseResponse(data) {
  return data?.shows?.node;
}

/** @param {getDataPayload} payload */
function getQuery(payload = {}) {
  return generateQuery(
    `query filterShows($imageSize: String!, $imageType: ImageTypeEnum!, $slugs: [String]!) {
        shows(slugIn: $slugs) {
          node {
            title
            slug
            webSlug
            broadcast
            images(imageType: $imageType){
              url(size: $imageSize)
            }
          }
        }
      }`,
    {
      imageSize: imageSize(imageSize.type.show),
      imageType: 'LANDSCAPE',
      ...payload,
    },
  );
}

/**
 * @typedef {{
 *   data: Show[],
 *   getData: function(getDataPayload): Promise<Show[]>,
 *   ...import('./useGQL').UseGQLReturn
 * }} CustomHookReturn
 */

/**
 * @returns {CustomHookReturn}
 */
export default function useFilterShows() {
  return useGQL(parseResponse, getQuery, {
    loading: false,
  });
}

/**
 * @param {{
 *   imageType: string,
 *   imageSize: string,
 *   slugs: string[],
 * }}
 */
export async function getFilterShow(payload) {
  try {
    const response = await fetchApi(getQuery(payload));
    const result = parseResponse(await response.json());
    return Promise.resolve(result);
  } catch (e) {
    return Promise.reject(e);
  }
}
