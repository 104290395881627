import IconHome from '@components/icons/IconHome';
import MenuItem from '@components/MenuItem';
import IconSearch from '@components/icons/IconSearch';
import IconLive from '@components/icons/IconLive';
import IconCategories from '@components/icons/IconCategories';
import IconFavorite from '@components/icons/IconFavorite';
import IconProfile from '@components/icons/IconProfile';
import IconLogo from '@components/icons/IconLogo';
import {useState} from 'react';
import MenuSubItem from '@components/MenuSubItem';
import IconLogoFull from '@components/icons/IconLogoFull';
import {useNavigate} from 'react-router-dom';
import appRoutes from '@app/appRoutes';
import {useDispatch, useSelector} from 'react-redux';
import {getCategories} from '@app/stores/shows';
import {keyNavAttribute, keyNavSelectors, KEY_NAV} from './KeyNavItem';
import {useEffect} from 'react';
import {useRef} from 'react';
import {getIsOpenMenu, setIsOpenMenu} from '@app/stores/layout';
import platform from '@app/platform';
import IconOff from './icons/IconOff';

const menuItemKeyNav = {
  keynavdown: keyNavSelectors().steps('nextSibling').build(),
  keynavup: keyNavSelectors().steps('previousSibling').build(),
  keynavright: keyNavSelectors()
    .querySelector(
      `#menu.menu-secundary-open #menu-secundary [${KEY_NAV.ITEM_LAST_FOCUS}]`,
      `#menu.menu-secundary-open #menu-secundary [${KEY_NAV.ITEM}]`,
    )
    .querySelector(
      '#app-content [keynavparentlastfocus] [keynavitemlastfocus]',
      '#app-content [keynavitemlastfocus]',
      '#app-content [keynavitem]',
    )
    .build(),
};

export default function Menu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isShowSecundary, setShowSecundary] = useState(false);
  const categories = useSelector(getCategories);
  const menuSecundary = useRef();
  const isOpenMenu = useSelector(getIsOpenMenu);
  const mainRef = useRef();
  const mainPanelRef = useRef();

  /**
   * selectMenuItem.
   *
   * @param {props} payload
   * @param {string} payload.navigate
   */
  function selectMenuItem(payload) {
    if (payload.navigate === 'categories') {
      dispatch(setIsOpenMenu(true));
      setShowSecundary(true);
    } else {
      navigate(payload.navigate);
      setShowSecundary(false);
      dispatch(setIsOpenMenu(false));
    }
  }

  function onParentBlurHandler() {
    setShowSecundary(false);
    dispatch(setIsOpenMenu(false));
  }

  function fixScrollPadding() {
    const childs = mainRef.current.querySelectorAll('.scrollhidden-child');
    const right =
      mainPanelRef.current.offsetWidth - mainPanelRef.current.clientWidth;
    childs.forEach(child => {
      child.style.marginRight = '-' + right + 'px';
    });
  }

  useEffect(() => {
    if (typeof menuSecundary.current?.addEventListener === 'function') {
      menuSecundary.current.addEventListener(
        KEY_NAV.EVENT_PARENT_BLUR,
        onParentBlurHandler,
      );
    }
    return () => {
      if (typeof menuSecundary.current?.removeEventListener === 'function') {
        menuSecundary.current.removeEventListener(
          KEY_NAV.EVENT_PARENT_BLUR,
          onParentBlurHandler,
        );
      }
    };
  }, []);

  useEffect(() => {
    fixScrollPadding();
  }, []);

  return (
    <div
      ref={mainRef}
      id="menu"
      className={`${isShowSecundary ? 'menu-secundary-open' : ''} ${
        isOpenMenu ? 'menu-open' : 'menu-close'
      }`}
      onMouseEnter={() => dispatch(setIsOpenMenu(true))}
      onMouseLeave={() => {
        setShowSecundary(false);
        dispatch(setIsOpenMenu(false));
      }}>
      <div id="menu-wrap">
        <div
          {...keyNavAttribute(KEY_NAV.PARENT)}
          id="menu-main"
          className="menu-panel scrollhidden">
          <div
            ref={mainPanelRef}
            className="menu-panel-inner scrollhidden-child">
            <div className="menu-panel-content">
              <div id="menu-main-items">
                <MenuItem
                  label="Buscar"
                  icon={<IconSearch width={20} height={20} />}
                  to={appRoutes.search()}
                  onClick={() => {
                    setShowSecundary(false);
                    dispatch(setIsOpenMenu(false));
                  }}
                  {...menuItemKeyNav}
                />
                <MenuItem
                  label="Inicio"
                  icon={<IconHome width={20} height={20} />}
                  to={appRoutes.home()}
                  onClick={() => {
                    setShowSecundary(false);
                    dispatch(setIsOpenMenu(false));
                  }}
                  keynavitemlastfocus={'keynavitemlastfocus'}
                  {...menuItemKeyNav}
                />
                <MenuItem
                  label="TV en vivo"
                  icon={<IconLive width={30} height={30} />}
                  to={appRoutes.live()}
                  onClick={() => {
                    setShowSecundary(false);
                    dispatch(setIsOpenMenu(false));
                  }}
                  {...menuItemKeyNav}
                />
                <MenuItem
                  label="Categorías"
                  icon={<IconCategories width={20} height={20} />}
                  className={
                    window.location.href.indexOf(appRoutes.category()) !== -1
                      ? 'active'
                      : ''
                  }
                  onClick={() => {
                    selectMenuItem({
                      navigate: 'categories',
                    });
                  }}
                  {...menuItemKeyNav}
                />
                <MenuItem
                  label="Favoritos"
                  icon={<IconFavorite width={25} height={20} />}
                  to={appRoutes.favorites()}
                  onClick={() => {
                    setShowSecundary(false);
                    dispatch(setIsOpenMenu(false));
                  }}
                  {...menuItemKeyNav}
                />
                <MenuItem
                  id={'menu-profile'}
                  label="Perfil"
                  icon={<IconProfile width={20} height={20} />}
                  to={appRoutes.profile()}
                  onClick={() => {
                    setShowSecundary(false);
                    dispatch(setIsOpenMenu(false));
                  }}
                  {...menuItemKeyNav}
                />
              </div>
              <div id="menu-main-footer">
                <IconLogo className="logo-mini" width={53} />
                <IconLogoFull className="logo-full" width={120} />
              </div>
            </div>
          </div>
        </div>
        <div
          ref={menuSecundary}
          {...keyNavAttribute(KEY_NAV.PARENT)}
          id="menu-secundary"
          className="menu-panel scrollhidden">
          <div
            id="menu-secundary-inner"
            className="menu-panel-inner scrollhidden-child">
            <div
              className="menu-panel-content"
              style={{
                transform: 'translateY(-15px)',
              }}>
              {!categories?.length && (
                <p style={{padding: '20px'}}>
                  No se pudo obtener las categorías
                </p>
              )}
              {categories?.length > 0 &&
                categories
                  ?.filter(item => {
                    if (item?.slug !== 'newsChapters') {
                      return true;
                    } else {
                      return false;
                    }
                  })
                  ?.map(item => {
                    return (
                      <MenuSubItem
                        key={'menu-' + item?.slug}
                        label={item?.title}
                        onClick={() => {
                          setShowSecundary(false);
                          dispatch(setIsOpenMenu(false));
                        }}
                        to={appRoutes.category(item?.slug)}
                        keynavdown={keyNavSelectors()
                          .steps(
                            'parentNode',
                            'nextSibling',
                            KEY_NAV.ITEM_LAST_FOCUS,
                          )
                          .build()}
                        keynavup={keyNavSelectors()
                          .steps(
                            'parentNode',
                            'previousSibling',
                            KEY_NAV.ITEM_LAST_FOCUS,
                          )
                          .build()}
                        keynavleft={keyNavSelectors()
                          .querySelector(
                            `#menu-main [${KEY_NAV.ITEM_LAST_FOCUS}]`,
                            `#menu-main [${KEY_NAV.ITEM}]`,
                          )
                          .build()}
                        keynavright={keyNavSelectors()
                          .querySelector(
                            '#app-content [keynavparentlastfocus] [keynavitemlastfocus]',
                            '#app-content [keynavitemlastfocus]',
                            '#app-content [keynavitem]',
                          )
                          .build()}
                      />
                    );
                  })}
            </div>
          </div>
        </div>
      </div>
      {platform.isTizen && (
        <div
          id={'menu-off'}
          onClick={() => {
            dispatch(setIsOpenMenu(false));
            platform.exit();
          }}>
          <IconOff width={20} height={20} />
          <span>Salir</span>
        </div>
      )}
    </div>
  );
}
