export function IconClose(props) {
  const fill = props.fill || '#f7f7f7';

  return (
    <svg
      width={props.width || 20}
      height={props.height || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.24 10.217L0.64 18.786C0.53022 18.9047 0.469241 19.0604 0.469241 19.222C0.469241 19.3837 0.53022 19.5394 0.64 19.658C0.748962 19.7732 0.898564 19.8414 1.057 19.848C1.21544 19.8414 1.36504 19.7732 1.474 19.658L10.116 11.017L18.761 19.662C18.813 19.7215 18.8771 19.7691 18.949 19.8018C19.0209 19.8344 19.099 19.8513 19.178 19.8513C19.257 19.8513 19.3351 19.8344 19.407 19.8018C19.4789 19.7691 19.543 19.7215 19.595 19.662C19.7048 19.5434 19.7658 19.3877 19.7658 19.226C19.7658 19.0644 19.7048 18.9087 19.595 18.79L11.102 10.217L19.671 1.61002C19.7808 1.49137 19.8418 1.33567 19.8418 1.17402C19.8418 1.01237 19.7808 0.856673 19.671 0.73802C19.5523 0.62824 19.3966 0.567261 19.235 0.567261C19.0734 0.567261 18.9177 0.62824 18.799 0.73802L10.154 9.41702L1.509 0.73802C1.39052 0.629094 1.23544 0.568644 1.0745 0.568644C0.913556 0.568644 0.758483 0.629094 0.64 0.73802C0.581449 0.79454 0.534881 0.862275 0.503076 0.937184C0.471271 1.01209 0.45488 1.09264 0.45488 1.17402C0.45488 1.2554 0.471271 1.33595 0.503076 1.41086C0.534881 1.48576 0.581449 1.5535 0.64 1.61002L9.24 10.217Z"
        fill={fill}
      />
    </svg>
  );
}
