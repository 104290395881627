const initialState = {};

export const ADD_PROFILE = 'ADD_PROFILE';
export const REMOVE_PROFILE = 'REMOVE_PROFILE';

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_PROFILE:
      return {
        ...state,
        ...action.payload,
      };
    case REMOVE_PROFILE:
      return {};
    default:
      return state;
  }
}

export function addProfile(payload) {
  return async dispatch => {
    return dispatch({
      type: ADD_PROFILE,
      payload: payload,
    });
  };
}

export function removeProfile() {
  return async dispatch => {
    return dispatch({
      type: ADD_PROFILE,
      payload: {},
    });
  };
}

export function getProfile(state) {
  return state.profile;
}
